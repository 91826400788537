import React from 'react'
import { Grid } from '@/components/Grid'
import viewImage from '@/images/view.png'
import { useGlobalStore } from '@/stores/globalStore'



const EmployeeListItem: React.FC<any> = ({ template, item }) => {
    const { setEmployee } = useGlobalStore()

    const { id, data } = item

    return (
        <Grid.Row $cols={template}>
            {data.map((prop, idx) => <Grid.Item key={idx}>{prop}</Grid.Item>)}
            {/* <Grid.Item>{item.id}</Grid.Item>
            <Grid.Item>{item.name}</Grid.Item>

            <Grid.Item>{item.department || 'Не указано'}</Grid.Item>
            <Grid.Item>{item.email}</Grid.Item>
            <Grid.Item>{item.phone}</Grid.Item> */}
            <Grid.Item>
                <a href="#" onClick={() => setEmployee(id)}>
                    <img src={viewImage} alt="view icon" />
                </a>
            </Grid.Item>
        </Grid.Row>
    )
}

export default EmployeeListItem