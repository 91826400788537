import { css, createGlobalStyle } from 'styled-components'

export const globalStyles = css`
 * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  * { min-width: 0; min-height: 0; }
  
  html {
    font-size: 62.5%;
  }
  
  html, body, #root {
    width: 100%;
    /* min-width: 100vh; */
    max-height: 100vh;
    height: 100%;
  }
  
  #root {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  body {
    font-family: "Roboto","Segoe UI","Helvetica Neue",Arial,sans-serif;
    width: 100%;
    height: 100%;
    font-size: 1.4rem;
  }
`

export const GlobalStyles = createGlobalStyle`${globalStyles}`

export default globalStyles