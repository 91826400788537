import React from 'react'
import styled from 'styled-components'
import Search from './Search'
import DateSelector from './DataSelector';
import UserBar from './UserBar';

const Appbar: React.FC = (props) => {
    return (
        <Wrapper>
            <Search />
            <DateSelector />
            <UserBar />
        </Wrapper>
    )   
}

export default Appbar

const Wrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 32px;
    padding: 0 2rem;
    height: 60px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    z-index: 2001;
    background: ${props => props.theme.palette.background.primary};
    color: ${props => props.theme.palette.text.primary};
`