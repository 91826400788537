import { ThemeProvider } from "styled-components"
import { GlobalStyles } from "./components/layout/GlobalStyles"
import { Reset } from "./components/layout/Reset"
import AppContainer from './containers/AppContainer'
import blueTheme from './ui/blueTheme'
import whiteTheme from './ui/whiteTheme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import "react-datepicker/dist/react-datepicker.css";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ReactQueryProvider from '@/lib/react-query'
import ToastProvider from "./components/shared/toast/ToastProvider"
import ConfirmDialog from "./components/ConfirmDialog"

const App = () => {
  return (
    <ThemeProvider theme={blueTheme}>
      <ToastProvider>
        <ReactQueryProvider>
          {/* Reset styling */}
          <Reset />
          <GlobalStyles />

          {/* Main Application */}
            <AppContainer />
            <ConfirmDialog />
        </ReactQueryProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}

export default App