import React from 'react'
import Grid from '@/ui/components/Grid';
import { useFormikContext } from 'formik';
import { generateBookingGridDates } from '@/utils/helpers/dates.helpers';
import { useMediaQuery } from 'react-responsive';
import BookingGridSelector from './BookingGridSelector';
import { ErrorBoundary } from 'react-error-boundary';


const BookingGridField = ({ bookings, addItem }) => {
    const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' })
    const { values } = useFormikContext<any>()

    if (values.type === '3' || !isBigScreen) return null

    return (
        <ErrorBoundary
            fallback={<div>Не удалось инициализировать календарь</div>}
        >
            <Grid container>
                <Grid item xs={12} md={12} >
                    <BookingGridSelector
                        bookings={bookings}
                        addItem={addItem}
                    />
                </Grid>
            </Grid>
        </ErrorBoundary>
    )
}

export default BookingGridField