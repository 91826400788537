import React from 'react'
import styled, { css } from 'styled-components'

export const NullAvatar = styled.div<{ $src?: string, $size?: string }>`
    ${({ $src }) => $src && css`
        background-image: url(${$src});
    `}
    background-color: ${({ theme }) => theme.palette.text.primary};
    ${({ $size }) => {
        switch($size) {
            case 'large': {
                return css`
                    width: 100px;
                    height: 100px;
                `
            }
            case 'big': {
                return css`
                    width: 60px;
                    height: 60px;
                `
            }
            default: {
                return css`
                    width: 40px;
                    height: 40px;
                `
            } 
        }
    }}
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`

const Avatar = ({ src, size = 'medium' }) => {
    return <NullAvatar $src={src} $size={size} />
}

export default Avatar