import React from 'react'
import styled, { css } from 'styled-components'
import { SectionType } from '@/containers/SidebarContainer';
import { useGlobalStore } from '@/stores/globalStore';

interface MenuItemProps {
    name: string
    selector: SectionType
    icon: React.ElementType
}

const MenuItem: React.FC<MenuItemProps> = ({ name, selector, icon: Icon }) => {
    const sidebarStore = useGlobalStore()

    const isActive = selector === sidebarStore.selector
    const onChange = () => sidebarStore.setSelector(isActive ? null : selector)

    return (
        <Item $active={isActive} onClick={onChange}>
            <Icon />
            <p>{name}</p>
        </Item>
    )
}

export default MenuItem

const Item = styled.div<{ $active?: boolean }>`
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    padding: 2rem 1rem;
    position: relative;
    transition: background 0.5s, color 0.5s;

    &:not(:last-child) {
        border-bottom: 1px solid ${props => props.theme.palette.text.primary};
    }

    &:hover {
        background: ${props => props.theme.palette.accent.main};
        color: ${props => props.theme.palette.text.secondary};
    }

    img {
        z-index: 2;
        user-select: none;
    }

    ${({ $active }) => $active && css`
        background: ${props => props.theme.palette.accent.main};
        color: ${props => props.theme.palette.text.secondary};
    `}
`