import React from "react"
import { useField, useFormikContext } from "formik"
import DatePicker from "react-datepicker"
import { Input } from "./Input"
import styled from 'styled-components';
import { ru } from 'date-fns/locale';

export const DatePickerField = ({ name, ...props }) => {
    const { setFieldValue } = useFormikContext()
    const [field] = useField(props.field)

    return (
        <DatePicker
            timeCaption="Время"
            timeFormat="HH:mm"
            timeIntervals={30}
            dateFormat="Pp"
            {...field}
            {...props}
            name={field.name}
            selected={(field.value && new Date(field.value)) || null}
            showTimeSelect
            locale={ru}
            customInput={<CustomDatePickerInput />}
            onChange={val => {
                setFieldValue(field.name, val);
            }}
        />
    )
}

const CustomDatePickerInput = styled(Input)`
    width: 100%;
`