import React from 'react'
import { Grid } from '@/components/Grid'
import viewImage from '@/images/view.png'
import { NodeElement } from '@/api/project'
import locationImage from '@/images/location.png'
import { useProjectStore } from '@/stores/projectStore';
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'

type ElementsListItemProps = {
    item: NodeElement
}

const ElementsListItem: React.FC<ElementsListItemProps> = ({ item }) => {
    const { setSeat, setActiveLayer } = useGlobalStore()
    const { setZoomSeat } = useMapStore()

    const handleSeatView = (e) => {
        e.preventDefault()
        console.log(item.parent);
        
        setActiveLayer(Number(item.parent))
        setSeat(Number(item.id))
        setZoomSeat(Number(item.id))
    }

    const handleSeatChange = (e) => {
        e.preventDefault()
        setSeat(Number(item.id))
    }

    return (
        <Grid.Row $cols="40px 2fr 1fr 1fr 30px 30px">
            <Grid.Item>{item.id}</Grid.Item>
            <Grid.Item>{item.name}</Grid.Item>
            <Grid.Item>
                <Place item={item} />
            </Grid.Item>
            <Grid.Item>{item.type_name}</Grid.Item>
            <Grid.Item>
                <a href="#"onClick={handleSeatView}>
                    <img src={locationImage} alt="location icon" />
                </a>
            </Grid.Item>
            <Grid.Item>
                <a href="#" onClick={handleSeatChange}>
                    <img src={viewImage} alt="view icon" />
                </a>
            </Grid.Item>
        </Grid.Row>
    )
}

export default ElementsListItem

export const Place = ({ item }) => {
    const { nodes } = useProjectStore()
    const place = nodes.find(tree => tree.id == item.parent)

    return (
        <div>{place ? place.name : 'Не указано'}</div>
    )
}
