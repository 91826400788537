import * as yup from 'yup'

export const bookingSchema = yup.object().shape({
    seat: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required()
    }).required(),
    user: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required()
    }).required('Выберите сотрудника'),
    type: yup.string().required(),
    // dates: yup.array(yup.object().shape({
    //     start: yup.string().required(),
    //     end: yup.string().required()
    // })).required(),
})

export const bookingConstantSchema = yup.object().shape({
    place: yup.string().required('Выберите расположение'),
    placeId: yup.number().required(),
    spot: yup.string().required('Выберите место'),
    type: yup.string().required(),
    employee: yup.string().required('Выберите сотрудника'),
    employeeId: yup.string().required(),
    start: yup.string().required(),
})

export const bookingRecurentSchema = yup.object().shape({
    place: yup.string().required('Выберите расположение'),
    placeId: yup.number().required(),
    spot: yup.string().required('Выберите место'),
    type: yup.string().required(),
    employee: yup.string().required('Выберите сотрудника'),
    employeeId: yup.string().required(),
    start: yup.string().required(),
    end: yup.string().required(),
    dates: yup.array(yup.object().shape({
        start: yup.string().required(),
        end: yup.string().required()
    })).required(),
})