import { MeData } from '../api/user'
import create from 'zustand'

type MapState = {
    initialized: boolean
    scale: number
    point: MapPointType
    offset: MapPointType
    stage: any
    zoomSeat: number | null
    setInitialized: (initialized: boolean) => void
    setZoomSeat: (seat: number) => void
    setStage: (stage) => void
    setScale: (scale: number) => void
    setPoint: ({ x, y }: MapPointType) => void
    setOffset: ({ x, y }: MapPointType) => void
    onRescale: ({ scale, point }: OnRescaleProps) => void
}

export const useMapStore = create<MapState>(set => ({
    initialized: false,
    scale: 1,
    stageHeight: 0,
    stageWidth: 0,
    point: {
        x: 0,
        y: 0
    },
    offset: {
        x: 0,
        y: 0
    },
    stage: null,
    zoomSeat: null,
    setInitialized: (initialized) => set(() => ({ initialized })),
    setZoomSeat: (zoomSeat) => set(() => ({ zoomSeat })),
    setStage: (stage) => set(() => ({ stage, initialized: true })),
    setScale: (scale) => set(() => ({ scale })),
    setPoint: (point) => set(() => ({ point })),
    setOffset: (offset) => set(() => ({ offset })),
    onRescale: ({ scale, point }) => set(() => ({ scale, point, zoomSeat: null })) 
}))

type MapPointType = {
    x: number
    y: number
}

interface OnRescaleProps {
    scale: number
    point: MapPointType
}