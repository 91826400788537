import { RuntimeFieldData, UserData } from "@/api/user"

type UserItem = {
    id: number
    name: string
    department: string
    email: string
    phone: string
}

export type UserExtra = {
    label: string
    accessor: string
    uid: string
}

export const makeEmployeeData = (data: UserData[] | undefined, columns: UserExtra[] | undefined) => {
    if (!data || !columns) return []

    return data.map((user) => {
        const userData = [Number(user.id), user.display]

        columns.forEach(col => {
            userData.push(user[col.uid] || '')
        })

        return { id: Number(user.id), data: userData}
    })
}