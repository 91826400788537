import React, { useState, useMemo, useEffect } from 'react'
import Sidebar from '../Sidebar/Sidebar';
import { Input } from '@/ui/components/Field/Input';
import { Grid } from '@/components/Grid';
import ElementsListItem from './ElementsListItem';
import Toolbar from '../Toolbar/Toolbar';
import { SelectInput } from '@/ui/components/Field/Select';
import Pagination from '@/ui/components/Pagination/Pagination';
import { useNodes } from '@/api/hooks/useNodes';
import styled from 'styled-components';
import { debounce } from 'lodash';
import { useProjectStore } from '@/stores/projectStore';
import { useMetadata } from '@/api/hooks/useMetadata';

const Elements = () => {
    const { nodes } = useProjectStore()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [parent, setParent] = useState<string>('')
    const [type, setType] = useState<string>('')
    const [name, setName] = useState<string>('')

    const { data, isLoading } = useNodes({ page: currentPage - 1, name, parent, type })
    const { metadata } = useMetadata()
    const handleNameChange = (e) => setName(e?.target?.value)
    const handleParentChange = (e) => setParent(e?.target?.value)
    const handleTypeChange = (e) => setType(e?.target?.value)

    const debouncedNameResponse = useMemo(() => {
        return debounce(handleNameChange, 500)
    }, [])

    useEffect(() => {
        return () => debouncedNameResponse.cancel()
    }, [])

    return (
        <Sidebar minWidth={900}>
            <Sidebar.Header title="Объекты" onAdd={() => alert('Добавление Объекты')} />

            <Toolbar>
                <Toolbar.Item xs={12} md={4}>
                    <Toolbar.Label>Поиск по названию</Toolbar.Label>
                    <Input $fullWidth placeholder="Поиск" onChange={debouncedNameResponse} />
                </Toolbar.Item>
                <Toolbar.Item xs={12} md={4}>
                    <Toolbar.Label>Расположение</Toolbar.Label>
                    <SelectInput $fullWidth placeholder="Выберите расположение" value={parent} onChange={handleParentChange}>
                        <option value="">Все уровни</option>
                        {nodes.map(node => <option key={node.id} value={node.id}>{node.name}</option>)}
                    </SelectInput>
                </Toolbar.Item>
                <Toolbar.Item xs={12} md={4}>
                    <Toolbar.Label>Тип объектов</Toolbar.Label>
                    <SelectInput $fullWidth placeholder="Выберите тип места"  value={type} onChange={handleTypeChange}>
                        <option value="">Все</option>
                        {metadata?.nodes?.map(node => <option key={node.uid} value={node.uid}>{node.name}</option>)}
                    </SelectInput>
                </Toolbar.Item>
            </Toolbar>

            <Grid>
                <Grid.RowHeader $cols="40px 2fr 1fr 1fr 30px 30px">
                    <Grid.Item>id</Grid.Item>
                    <Grid.Item>Наименование</Grid.Item>
                    <Grid.Item>Расположение</Grid.Item>
                    <Grid.Item>Тип</Grid.Item>
                    <Grid.Item />
                    <Grid.Item />
                </Grid.RowHeader>

                {data?.items.length ? data?.items.map(spot => <ElementsListItem key={spot.id} item={spot} />) : (
                    <NotFound>Ничего не найдено</NotFound>
                )}

            </Grid>

            <Pagination currentPage={currentPage} total={data?.total || 0} handlePageChange={setCurrentPage} />

        </Sidebar>
    )
}

export default Elements


const NotFound = styled.div`
    width: 100%;
    text-align: center;
    padding: 12px 0;
`