import React from 'react'

const CloseIcon = ({ color = 'white' }) => {
    return (
        <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="14" stroke={color} strokeWidth="2"/>
            <rect width="2.42436" height="14.5462" rx="1" transform="matrix(-0.707108 0.707106 -0.707108 -0.707106 20.9999 19.2856)" fill={color}/>
            <rect width="2.42436" height="14.5462" rx="1" transform="matrix(-0.707108 -0.707106 0.707108 -0.707106 10.7142 21)" fill={color}/>
        </svg>
    )
}

export default CloseIcon