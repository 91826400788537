import React, { useState } from 'react'
import styled from 'styled-components'
import ExportIcon from '@/components/icons/ExportIcon';
import view from '@/images/view.png'
import ReportModal from './ReportModal';

type ReportsItemProps = {
    name: string
}

const ReportsItems: React.FC<ReportsItemProps> = ({ name }) => {
    const [report, setReport] = useState<string | null>(null)

    return (
        <>
            <ReportWrapper>
                <ReportName>{name}</ReportName>
                <ReportControls>
                    {/* <ReportControlIconButton onClick={() => setReport('parking')}>
                        <ExportIcon />
                    </ReportControlIconButton> */}
                    <ReportControlButton onClick={() => setReport('parking')}>
                        <img src={view} alt="view icon" />
                    </ReportControlButton>
                </ReportControls>
            </ReportWrapper>
            <ReportModal report={report} onClose={() => setReport(null)} />
        </>
    )
}

export default ReportsItems

const ReportWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
`

const ReportName = styled.div`
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: #FFFFFF;
`

const ReportControls = styled.div`
    display: flex;
    align-items: center;
`

const ReportControlButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    background: transparent;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 0.8rem;
    }
`

const ReportControlIconButton = styled(ReportControlButton)`
    width: 30px;
    height: 30px;
    background: #F8DC4D;
    border-radius: 50%;
`