import { useGlobalStore } from '@/stores/globalStore';
import { RolesEnum, useUserStore } from '@/stores/userStore';
import React from 'react'
import styled, { css } from 'styled-components'
import Close from '../Close';
import PlusIcon from '../icons/PlusIcon';

const Sidebar = ({ children, minWidth = 0 }) => {
    return (
        <Wrapper $minWidth={minWidth}>
            {children}
        </Wrapper>
    )
}

export default Sidebar

const Wrapper = styled.div<{ $minWidth?: number }>`
    width: 100%;
    max-height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    ${({ $minWidth }) => $minWidth && css`
        min-width: ${$minWidth}px;
    `}
`

const Title = styled.div`
    font-weight: 500;
    font-size: 2.4rem;
    line-height: 3.2rem;
    color: ${props => props.theme.palette.text.primary};
`

const Box = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Header: React.FC<{ title: string, showAdd?: boolean, onAdd?: (...args: any) => void }> = ({ title, onAdd, showAdd }) => {
    const { setSelector } = useGlobalStore()
    const { role } = useUserStore()

    const showHeader = showAdd || role === RolesEnum.Admin

    return (
        <TitleContainer>
            <TitleWrapper>
                <Sidebar.Title>{title}</Sidebar.Title>
                {showHeader && (
                    <Add onClick={onAdd}>
                        <PlusIcon size={16} />
                    </Add>
                )}
            </TitleWrapper>
            <Close onClick={() => setSelector(null)} />
        </TitleContainer>
    )
}

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-shrink: 0;
`

const Add = styled.div`
    margin-left: 12px;
    width: 30px;
    height: 30px;
    background: #044D8C;
    border: 1px solid #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
`

const Content = styled.div`
    padding: 1rem 0;
`

const TitleContainer = styled(Box)`
    flex-shrink: 0;
`

Sidebar.Title = Title
Sidebar.Box = Box
Sidebar.Header = Header
Sidebar.Content = Content
