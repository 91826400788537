import React from 'react'
import styled from 'styled-components'
import { useGlobalStore } from '@/stores/globalStore';
import { useQuery } from 'react-query';
import { API } from '@/api';
import { useParams } from 'react-router-dom';

const FloorBadge = () => {
    const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
    const { activeLayer } = useGlobalStore()
    const { data, isLoading } = useQuery(
        'layer_info_' + activeLayer,
        () => API.layer.getLayerInfo({ workspaceId: Number(workspaceId), projectId: Number(projectId), layerId: Number(activeLayer)}),
        { enabled: !!(workspaceId && projectId && activeLayer), refetchOnMount: false }
    )

    if (!data || !data.layer_info && !isLoading) return null

    const badge = () => {
        const hasOwnView = data.layer_info.own_view
        return hasOwnView ? [...data.layer_info.parent_name, data.layer_info.name] : data.layer_info.parent_name
    }
    
    return (
        <Wrapper>
            {isLoading ? 'Загрузка' : badge().join('. ')}
        </Wrapper>
    )
}

export default FloorBadge

const Wrapper = styled.div`
    background: #044D8C;
    border: 1px solid #044D8C;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 3rem;
    color: #FFFFFF;
    padding: 1rem;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 75px;
    z-index: 199;
    user-select: none;

    pointer-events: none;
`