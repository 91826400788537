import React, { useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRange } from 'react-date-range';
import { ru } from 'date-fns/locale';
import { usePopper } from 'react-popper';
import { Portal } from 'react-portal'
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { animated, useTransition } from 'react-spring';
import { easePoly } from 'd3-ease';
import { format, endOfDay, startOfDay } from 'date-fns'
import { useBookingsForLayer } from '@/api/hooks/useBookingsForLayer';
import { useGlobalStore } from '@/stores/globalStore';

const DateSelector: React.FC = () => {
    const [open, setOpen] = useState(false)
    const [referenceElement, setReferenceElement] = useState()
    const [popperElement, setPopperElement] = useState()

    let { styles, attributes } = usePopper(referenceElement, popperElement)

    const { selection, setSelection } = useGlobalStore()

    const ref = useRef<any>()

    const handleSelect = (ranges) => {
        const { startDate, endDate } = ranges.selection
        setSelection(startDate, endDate)
    }

    const transitions = useTransition(open, {
        from: { opacity: 0, scale: 0.8 },
        enter: { opacity: 1, scale: 1 },
        leave: { opacity: 0, scale: 0.8 },
        reverse: open,
        config: {
            duration: 300,
            easing: easePoly.exponent(2),
        },
    })

    useOnClickOutside(ref, useCallback(() => setOpen(false), []))

    return (
        <ShowContainer ref={setReferenceElement as any}>
            <ShowLabel>Показать брони на карте</ShowLabel>
            <SelectedDate onClick={() => setOpen(!open)}>
                <p>{format(selection.startDate, 'dd.MM.yyyy HH:mm')} - {format(selection.endDate, 'dd.MM.yyyy HH:mm')}</p>
                <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect y="1" width="1.41421" height="8.48527" transform="rotate(-45 0 1)" fill="#FDFDFD" />
                    <rect x="11" width="1.41421" height="8.48527" transform="rotate(45 11 0)" fill="#FDFDFD" />
                </svg>
            </SelectedDate>
            {transitions(
                (style, item) => item && (
                    <Portal>
                        <ShowPicker
                            ref={setPopperElement as any}
                            style={{ ...styles.popper, ...style }}
                            {...attributes.popper}
                        >
                            <Wrapper ref={ref}>
                                <DateRange
                                    ranges={[selection]}
                                    onChange={handleSelect}
                                    locale={ru}
                                    months={2}
                                    showDateDisplay={false}
                                    direction="horizontal"
                                />
                                <AcceptButton onClick={() => setOpen(!open)}>Выбрать период</AcceptButton>
                            </Wrapper>
                        </ShowPicker>
                    </Portal>
                )
            )}
        </ShowContainer>
    )
}

export default DateSelector

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: flex-end; */
`

const AcceptButton = styled.button`
    border: none;
    outline: none;
    background-color: #3d91ff;
    color: #fff;
    padding: 15px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        background-color: #005fdb;
    }
`

const ShowPicker = styled(animated.div)`
    box-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
    z-index: 1001;
`

const ShowContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ShowLabel = styled.div`
    font-size: 1.2rem;
    line-height: 1.6rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
`

const SelectedDate = styled.div`
    font-size:  1.6rem;
    line-height:  1.6rem;
    text-align: center;
    color: #FFFFFF;
    padding: 8px 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
        padding-right: 8px;
    }
`