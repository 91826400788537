import { api, ApiResponseType } from "@/api";

export const UserService = {
    async auth({ login, password, workspaceId }: AuthDto) {
        const data = new FormData()
        data.append('login', login)
        data.append('password', password)
        data.append('workspace_id', String(workspaceId))

        return api.post<MeResponse>(`App/Auth/API?action=auth`, data)
    },
    async getMe(workspaceId: number) {
        return api.get<MeResponse>(`App/Auth/API?action=get_me&id=${workspaceId}`)
    },
    async getUsersList({ workspaceId, projectId, page = 0, perPage = 20, name }: UserListDto) {
        const data = new FormData()
        data.append('perpage', String(perPage))
        data.append('page', String(page))
        data.append('sortDirection', String(0))

        if (name !== '') {
            // data.append('filters[login]', String(filter))
            data.append('filters[display]', String(name))
        }

        return api.post<UsersListResponse>(`project/${workspaceId}/${projectId}/API?action=user_list`, data)
    },
    async getUser({ workspaceId, projectId, userId }: GetUserDto) {
        return api.post<GetUserResponse>(`project/${workspaceId}/${projectId}/API?action=get_user&id=${userId}`)
    },
    async getExtraFields({ workspaceId, projectId }: UserListDto) {
        return api.post<ExtendRuntimeResponse>(`project/${workspaceId}/${projectId}/API?action=extend_runtime_lister&intent=ws_user`)
    },
    async getRuntimeFields({ workspaceId, projectId }: UserListDto) {
        return api.get<RuntimeFieldsResponse>(`project/${workspaceId}/${projectId}/API?action=get_runtime_fields&intent=ws_user`)
    },
    // get_runtime_fields&intent=ws_user
}

export interface RuntimeFieldData {
    throw_import: boolean
    fappendmode: boolean
    read_path: string
    write_path: string
    css: string
    hint: string
    uid: string
    type: string
    alias: string
    label: string
    sort: number
    block: string
    mask: number
    attribute: string
    options:{
        email_domain: null
    }
}

interface RuntimeFieldsResponse extends ApiResponseType {
    required_fields: RuntimeFieldData[]
}
interface ExtendRuntimeResponse extends ApiResponseType {
    runtime_fields: RuntimeFieldData[]
}

interface GetUserResponse extends ApiResponseType {
    user: IUserData
}

export interface IUserData {
	updated_timestamp: string;
	protected: boolean;
	workspace_id: number;
	plugin_data: any;
	id: number;
	login: string;
	display: string;
	role: string;
	enabled: boolean;
}
interface GetUserDto {
    workspaceId: number
    projectId: number
    userId: number
}
interface AuthDto {
    workspaceId: number
    login: string
    password: string
}
interface UserListDto {
    workspaceId: number
    projectId: number
    page?: number
    perPage?: number
    name?: string
}
export interface UsersListResponse extends ApiResponseType {
    items: UserData[]
    total: number
}

export type UserData = {
    id: string
    login: string
    display: string
    updated_timestamp: string
}
export interface MeResponse extends ApiResponseType {
    me_data: MeData
}

export interface MeData {
    ui: IUser
    root_admin: true
}

export interface IUser {
    workspace_id: 0
    user_id: 0
    login: string
    display: string
    enabled: true
    role_id: string
    role: string
    projects: {
        [key: string]: string
    }
    class_version: string
}