import { RolesEnum, useUserStore } from '@/stores/userStore'
import { InputField } from '@/ui/components/Field/Input'
import { FormLabel } from '@/ui/components/Form/FormLabel'
import Grid from '@/ui/components/Grid'
import { Field, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { Portal } from 'react-portal';
import { ModalSelectInputField } from '../shared/ModalSelectInputField'
import SelectUserModal from './SelectUserModal'

const SelectUserField = () => {
    const [isOpen, setOpen] = useState(false)
    const { role } = useUserStore()
    const { setFieldValue } = useFormikContext()

    const handleEmployeeSelection = () => {
        if (role == RolesEnum.Admin) {
            setOpen(true)
        }
    }

    return (
        <>
            <Grid item xs={12} md={4} onClick={handleEmployeeSelection}>
                <FormLabel>Выберите сотрудника</FormLabel>
                <Field
                    name="user"
                    $fullWidth
                    type="text"
                    placeholder="Сотрудник"
                    component={ModalSelectInputField}
                    disabled={role !== RolesEnum.Admin}
                />
            </Grid>
            <Portal>
                <SelectUserModal isOpen={isOpen} setFieldValue={setFieldValue} onClose={() => setOpen(false)} />
            </Portal>
        </>
    )
}

export default SelectUserField