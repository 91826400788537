import React from 'react'

const MinusIcon = ({ color = '#F8DC4D' }) => {
    return (
        <svg width="24" height="5" viewBox="0 0 24 5" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.75" y="0.75" width="22.5" height="3.75" fill={color} />
        </svg>
    )
}

export default MinusIcon