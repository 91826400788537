import React from 'react'

const AddIcon = ({ color = '#000' }) => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="2.42436" height="14.5462" rx="1" transform="matrix(-1 -1.30655e-06 -1.13796e-06 -1 10.2122 16.2732)" fill={color} />
            <rect width="2.42436" height="14.5462" rx="1" transform="matrix(-1.30655e-06 -1 1 1.13796e-06 1.72687 10.2124)" fill={color} />
        </svg>
    )
}

export default AddIcon