import { api, BASE_DOMAIN } from "../api";
import { ApiResponseType } from "./types";

export const layerAPI = {
    getLayerView({ workspaceId, projectId, layerId }: LayerViewDto) {
        const data = new FormData()
        data.append('id', `${layerId}`)

        return api.post<LayerViewResponse>(`project/${workspaceId}/${projectId}/API?action=get_layer_view`, data).then(res => res.data)
    },
    layerImage({ layerId, imageName, extension }: LayerImageDto) {
        return `${BASE_DOMAIN}media/layer/${layerId}/${imageName}.S!default.${extension}`
    },
    getLayerInfo({ workspaceId, projectId, layerId }: LayerViewDto) {
        return api.get<LayerInfoResponse>(`project/${workspaceId}/${projectId}/API?action=get_layer&id=${layerId}`).then(res => res.data)
    }
}

interface LayerImageDto {
    imageName: string
    extension: string
    layerId: number
}

interface LayerViewDto {
    workspaceId: number
    projectId: number
    layerId: number
}

interface LayerViewResponse extends ApiResponseType {
    layer_view: {
        image: LayerImage
        polygons: PolygonType[]
        points: PointType[]
        map_node: {
            own_view: boolean
            label_size: number
            font_size: number
            color: string
            borderwidth: number
        }
    }
}

export interface PointType {
	raw_data?: any;
	workspace: number;
	metablock: number;
	project: number;
	id: number;
	name: string;
	parent: number;
	type_uid: string;
	type_name: string;
	x: number;
	y: number;
}

export interface PolygonData {
	stroke: string;
	fill: string;
	alpha: number;
	hover: number;
	polygon: number[][];
}

export interface PolygonType {
	own_view: boolean;
	id: number;
	name: string;
	type_name: string;
	polygon: PolygonData;
}

interface LayerInfoResponse extends ApiResponseType {
    layer_info: {
        color: string;
        font_size: number;
        borderwidth: number;
        font_options: number;
        wrap_text: boolean;
        clip_text: boolean;
        scale_text: boolean;
        label_size: number;
        id: number;
        parent?: any;
        own_view: boolean
        parent_name: string[];
        name: string;
        type_uid: string;
        info: string;
        closest_map_layer: number
    }
}

export interface LayerImage {
	sort: number;
	context_name: string;
	owner_id: string;
	image_id: string;
	mime: string;
	properties: any[];
	crops: any[];
	valid: boolean;
	extension: string;
	mime_safe: string;
}

export type UserData = {
    id: string
    login: string
    display: string
    updated_timestamp: string
}