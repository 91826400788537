import create from 'zustand'
import { SectionType } from '@/containers/SidebarContainer'
import { TreeNodeData } from '@/components/Elements/tree'

type ProjectState = {
    nodes: TreeNodeData[]
    setNodes: (nodes: TreeNodeData[]) => void
}

export const useProjectStore = create<ProjectState>(set => ({
    nodes: [],
    setNodes: (nodes) => set(() => ({ nodes }))
}))
