import React from 'react'

const PlusIcon = ({ color = '#F8DC4D', size = 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M13.875 0.75H10.125V10.125H0.75V13.875H10.125V23.25H13.875V13.875H23.25V10.125H13.875V0.75Z" fill={color} />
        </svg>
    )
}

export default PlusIcon