import { DefaultButton } from '@/components/ui/button/DefaultButton'
import { PrimaryButton } from '@/components/ui/button/PrimaryButton'
import FormLoader from '@/components/ui/form/FormLoader'
import { bookingSchema } from '@/utils/validation/booking.validation'
import { Field, Formik } from 'formik'
import React from 'react'
import styled, { css } from 'styled-components'
import { useBookingDialogStore } from './BookingModal'
import Grid from '@/ui/components/Grid';
import { FormLabel } from '@/ui/components/Form/FormLabel';
import { SelectField } from '@/ui/components/Field/Select';
import { bookingTypes } from '@/utils/constants/booking.constants'
import { DatePickerField } from '@/ui/components/Field/DatePickerField'
import { useBooking } from '@/api/hooks/useBooking';
import { useSeat } from '@/api/hooks/useSeat';
import BookingGridField from './booking-grid/BookingGridField';
import BookingIntervalField from './intervals/BookingIntervalsField'
import { SelectSeatField } from './select-seat/SelectSeatField';
import SelectUserField from './select-user/SelectUserField'
import { BookingData, NodeInfo } from '@/api/services/booking.service'
import { addMinutes, endOfWeek, parseISO, startOfWeek } from 'date-fns'
import { generateBookingGridDates } from '@/utils/helpers/dates.helpers'
import { RolesEnum, useUserStore } from '@/stores/userStore'
import { useProjectStore } from '@/stores/projectStore';
import { useEffect } from 'react';
import { useBookingStore } from '@/stores/bookingStore';
import { API } from '@/api'
import { useProject } from '@/hooks/useProject'
import { useQueryClient } from 'react-query'
import { StatusResponseEnum } from '@/api/types'
import { getBookingErrorMessage } from '@/utils/helpers/error.helpers'
import { useToast } from '@/components/shared/toast/useToast';

type BookingFormProps = {
    data: any
    isLoading: boolean
}

const d = new Date()
const offset = d.getTimezoneOffset()

const formatBeforeSubmit = (time) => addMinutes(time, offset)

const getDate = (type, date) => {
    if (!type || !date) return ''

    return parseISO(date + '.000Z')
}

const BookingForm: React.FC<BookingFormProps> = ({ data, isLoading }) => {
    const { bookingId, nodeId, onClose } = useBookingDialogStore()
    const { user, role } = useUserStore()
    const { nodes } = useProjectStore()
    const { setWeek, week } = useBookingStore()
    const { data: seat, isLoading: isSeatLoading } = useSeat(nodeId)
    const { workspaceId, projectId } = useProject()
    const queryClient = useQueryClient()
    const { enqueueToast } = useToast()
    const weekStart = startOfWeek(week, { weekStartsOn: 1 })
    const weekEnd = endOfWeek(week, { weekStartsOn: 1 })

    let boundArrayHelpers
    const bindArrayHelpers = (arrayHelpers) => {
        boundArrayHelpers = arrayHelpers
    }

    useEffect(() => {
        if (!data || !data.booking) {
            setWeek(startOfWeek(new Date(), { weekStartsOn: 1 }))
        } else {
            setWeek(startOfWeek(parseISO(data.booking.start + '.000Z'), { weekStartsOn: 1 }))
        }
    }, [data])

    const handleFormSubmit = async (values, { setSubmitting }) => {
        setSubmitting(true)
        try {
            const bId = data?.booking.id ? Number(data.booking.id) : null
            const response = await API.bookings.add({
                bookingId: bId,
                workspaceId,
                projectId,
                resourceId: Number(values.seat.id),
                userId: RolesEnum.Admin === role ? Number(values.user.id) : null,
                bookingType: Number(values.type),
                dates: values.dates.map(item => ({ start: formatBeforeSubmit(item.start), end: formatBeforeSubmit(item.end) })),
                start: formatBeforeSubmit(values.start),
                end: formatBeforeSubmit(values.end)
            })

            if (response && response.status === StatusResponseEnum.Success) {
                queryClient.refetchQueries('bookings')
                queryClient.refetchQueries(['booking_intervals', workspaceId, projectId,  Number(values.seat.id), RolesEnum.Admin === role ? Number(values.user.id) : null, weekStart, weekEnd])
                onClose()
                enqueueToast({
                    title: 'Бронь добавлена!',
                    message: 'Ваше бронирование было успешно добавлено'
                }, { variant: 'success' })
            } else {
                throw new Error(response?.error_info.message)
            }
        } catch (e: any) {
            enqueueToast({
                title: 'Ошибка!',
                message: getBookingErrorMessage(e.message)
            }, { variant: 'error' })
        }
        setSubmitting(false)
    }

    return (
        <Formik
            validateOnChange={true}
            validationSchema={bookingSchema}
            onSubmit={handleFormSubmit}
            enableReinitialize
            initialValues={{
                type: String(data?.booking.booking_type || 1) || '1',
                seat: {
                    id: data?.node.id || seat?.node.id || '',
                    name: getSeatName(nodes, data?.node || seat?.node).join(". ")
                },
                user: {
                    id: data?.booking.user_id || user?.user_id || '',
                    name: data?.booking.user_name || user?.display || '',
                },
                dates: data?.slots|| [],
                start: getDate(data?.booking.booking_type, data?.booking.start),
                end: getDate(data?.booking.booking_type, data?.booking.end),
            }}
        >
            {({ handleSubmit, values, isSubmitting, isValid }) => (
                <form onSubmit={handleSubmit}>
                    <FormContainer>
                        <Grid container>
                            <SelectSeatField />
                            <SelectUserField />
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={4}>
                                <FormLabel>Тип брони</FormLabel>
                                <Field
                                    name="type"
                                    $fullWidth
                                    required={true}
                                    placeholder="Тип брони"
                                    component={SelectField}
                                    options={bookingTypes}
                                />
                            </Grid>
                        </Grid>

                        {values.type != '1' && (
                            <div>
                                <Grid container>
                                    <Grid item xs={12} md={4}>
                                        <FormLabel>Дата и время начала</FormLabel>
                                        <Field name="start" $fullWidth placeholder="Время начала" component={DatePickerField} />
                                    </Grid>
                                    {values.type === '2' && (
                                        <Grid item xs={12} md={4}>
                                            <FormLabel>Дата и время окончания</FormLabel>
                                            <Field name="end" $fullWidth type="text" placeholder="Время окончания" component={DatePickerField} />
                                        </Grid>
                                    )}
                                </Grid>
                            </div>
                        )}

                        <BookingIntervalField
                            bindArrayHelpers={bindArrayHelpers}
                        />

                        <BookingGridField
                            bookings={data}
                            addItem={(value) => boundArrayHelpers.push(value)}
                        />
                    </FormContainer>

                    <FormControls>
                        <PrimaryButton type="submit" disabled={isSubmitting || !isValid}>
                            {bookingId ? 'Сохранить' : 'Забронировать'}
                        </PrimaryButton>
                        <DefaultButton onClick={onClose} type="button">Отменить</DefaultButton>
                    </FormControls>

                    <FormLoader
                        isLoading={isLoading || isSeatLoading}
                    />
                </form>
            )}
        </Formik>
    )
}

const FormContainer = styled.div`
    padding: 1rem 0;
    max-height: 100%;
`

export default BookingForm

export const FormControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;

    & > {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
`

const getSeatName = (nodes: any, node: NodeInfo | undefined) => {
    if (!node) return []
    const currentNode = nodes.find(tree => Number(tree.id) === node.parent)
    return [currentNode?.name, node.name]
}

const makeFormData = (data: { booking: BookingData, node: NodeInfo } | undefined, seat: { node: NodeInfo } | undefined, nodes: any, user: any) => {
    return ({
        type: String(data?.booking.booking_type || 1) || '1',
        seat: {
            id: data?.node.id || seat?.node.id || '',
            name: getSeatName(nodes, data?.node || seat?.node).join(". ")
        },
        user: {
            id: data?.booking.user_id || user?.user_id || '',
            name: data?.booking.user_name || user?.display || '',
        },
        dates: generateBookingGridDates(data) || [],
        start: data?.booking.start ? parseISO(data?.booking.start + '.000Z') : '',
        end: data?.booking.end ? parseISO(data?.booking.end + '.000Z') : ''
    })
}