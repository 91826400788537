import styled, { css } from 'styled-components';

const ButtonBaseStyles = css`
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 1.2rem;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
    }
`

export const ButtonBase = styled.button`
    ${ButtonBaseStyles}
`