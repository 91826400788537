import { useUserStore } from '@/stores/userStore'
import Avatar from '@/ui/components/Avatar';
import React, { useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import styled from 'styled-components';
import { useQueryClient } from 'react-query';
import { API } from '@/api';
import useOnClickOutside from '@/hooks/useOnClickOutside';

const UserBar = () => {
    const { isAuthenticated, user, setUser, setAuthenticated } = useUserStore()
    const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
    const queryClient = useQueryClient()
    const [isOpen, setOpen] = useState(false)
    const dropdownRef = useRef(null)


    useOnClickOutside(dropdownRef, () => setOpen(false))

    const handleLogout = () => {
        localStorage.removeItem('token')
        setUser(null)
        setAuthenticated(false)
        queryClient.clear()
    }

    if (!isAuthenticated || !user) return (
        <LoginLink to={`/login?workspace_id=${workspaceId}&project_id=${projectId}`}>
            Вход
        </LoginLink>
    )

    return (
        <UserBarWrapper>
            <User onClick={() => setOpen(!isOpen)}>
                <Avatar src={API.user.getAvatar({ userId: Number(user.user_id) })} />
                <Name>{user.display}</Name>
            </User>
            {isOpen && (
                <Dropdown ref={dropdownRef}>
                    <Avatar size="big" src={API.user.getAvatar({ userId: Number(user.user_id) })} />
                    <UserInfo>
                        <DropdownName>{user.display}</DropdownName>
                        <ul>
                            <li>
                                <ProfileButton href="#">Профиль</ProfileButton>
                            </li>
                            <li onClick={handleLogout}>
                                <UserDropdownButton href="#">Выйти</UserDropdownButton>
                            </li>
                        </ul>
                    </UserInfo>
                </Dropdown>
            )}
        </UserBarWrapper>
    )
}

export default UserBar

const DropdownName = styled.div`
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    margin-bottom: 6px;
`

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const Dropdown = styled.div`
    position: absolute;
    top: 60px;
    right: 0px;
    max-width: 220px;
    width: 100%;
    border-radius: 0 0 5px 5px;
    padding: 10px;
    display: grid;
    grid-template-columns: 60px 1fr;
    column-gap: 10px;

    background: #1a6db2;

    ul {
        flex-grow: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


    a {
        text-decoration: none;
    }
`

const UserDropdownButton = styled.a`
    background: #FFFFFF;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    display: flex;
    padding: 0 5px;
    font-size: 12px;
    line-height: 24px;
    color: #000000;

    &:hover {
        opacity: 0.7;
    }
`

const ProfileButton = styled(UserDropdownButton)`
    background: #F8DC4D;
`

const User = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }
`

const LoginLink = styled(Link)`
    color: ${props => props.theme.palette.text.label};
    text-decoration: none;
`

const UserBarWrapper = styled.div`
    display: flex;
    align-items: center;
    min-width: 220px;
    justify-content: flex-end;
`

const Name = styled.div`
    margin-left: 1rem;
    font-size: 1.4rem;
    max-width: 100px;
`