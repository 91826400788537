import React from 'react'
import { useTheme } from 'styled-components'

const EmployeeIcon = () => {
    const { menu: { color, stroke } } = useTheme()
    
    return (
        <svg width="31" height="27" viewBox="0 0 31 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.05194 23.3116C1.05194 21.2396 3.15119 17.7349 9.83502 17.7349C16.5189 17.7349 18.6181 21.2396 18.6181 23.3116V26.4577H1.05194V23.3116Z" fill={color} stroke={stroke} />
            <path d="M14.5567 10.5506C14.5567 13.317 12.4212 15.5197 9.83501 15.5197C7.24883 15.5197 5.11328 13.317 5.11328 10.5506C5.11328 7.78421 7.24883 5.58154 9.83501 5.58154C12.4212 5.58154 14.5567 7.78421 14.5567 10.5506Z" fill={color} stroke={stroke} />
            <path d="M8.22113 22.481C8.22113 19.8555 10.87 15.5205 19.1181 15.5205C27.3662 15.5205 30.0151 19.8555 30.0151 22.481V26.4573H8.22113V22.481Z" fill={color} stroke={stroke} />
            <path d="M25.0289 6.81408C25.0289 10.2683 22.361 13.0286 19.1181 13.0286C15.8752 13.0286 13.2073 10.2683 13.2073 6.81408C13.2073 3.35986 15.8752 0.599609 19.1181 0.599609C22.361 0.599609 25.0289 3.35986 25.0289 6.81408Z" fill={color} stroke={stroke} />
        </svg>
    )
}

export default EmployeeIcon