import React from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { API } from '../api'
import { useGlobalStore } from '@/stores/globalStore';
import Map from '@/components/Map/Map';

interface Props {
}

export interface PointOptionsType {
    labelSize: number
    fontSize: number
    color: string
    borderWidth: number
}

const MapContainer: React.FC<Props> = () => {
    const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
    const { activeLayer } = useGlobalStore()

    const { data: layerData } = useQuery(
        'layer_view_' + activeLayer,
        () => API.layer.getLayerView({ workspaceId: Number(workspaceId), projectId: Number(projectId), layerId: Number(activeLayer) }),
        { enabled: !!(workspaceId && projectId && activeLayer), refetchOnMount: false }
    )

    if (!layerData || !layerData.layer_view) return null

    const imagePath = API.layer.layerImage({
        layerId: Number(layerData.layer_view.image.owner_id),
        imageName: layerData?.layer_view.image.image_id,
        extension: layerData.layer_view.image.extension
    })
    
    const pointOptions: PointOptionsType = {
        labelSize: layerData.layer_view.map_node.label_size / 100,
        fontSize: layerData.layer_view.map_node.font_size,
        color:  layerData.layer_view.map_node.color,
        borderWidth: layerData.layer_view.map_node.borderwidth,
    }

    return (
        <Map
            image={imagePath}
            polygons={layerData.layer_view.polygons}
            points={layerData.layer_view.points}
            pointOptions={pointOptions}
        />
    )
}

export default MapContainer
