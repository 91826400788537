import React from 'react'
import { useTheme } from 'styled-components'

const LayersIcon = () => {
    const { menu: { color, stroke } } = useTheme()

    return (
        <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0259 14.1044C16.4411 13.874 16.9302 13.8195 17.3586 13.9557L34.993 19.5635C36.1568 19.9336 36.2623 21.4066 35.1743 22.0949L24.2586 29.0006C23.8278 29.2731 23.299 29.3479 22.8382 29.2013L4.23199 23.2845C3.02564 22.9008 2.96946 21.3497 4.13843 20.701L16.0259 14.1044Z" fill={color} stroke={stroke} />
            <path d="M16.0258 9.35198C16.4409 9.1216 16.9301 9.06702 17.3585 9.20326L34.9929 14.8111C36.1567 15.1812 36.2622 16.6542 35.1742 17.3425L24.2585 24.2481C23.8277 24.5207 23.2989 24.5954 22.8381 24.4489L4.23187 18.532C3.02552 18.1484 2.96934 16.5973 4.1383 15.9486L16.0258 9.35198Z" fill={color} stroke={stroke} />
            <path d="M16.0257 4.63518C16.4408 4.40481 16.9299 4.35022 17.3584 4.48647L34.9928 10.0943C36.1566 10.4644 36.2621 11.9374 35.1741 12.6257L24.2584 19.5314C23.8276 19.8039 23.2987 19.8786 22.8379 19.7321L4.23174 13.8152C3.02539 13.4316 2.96922 11.8805 4.13818 11.2318L16.0257 4.63518Z" fill={color} stroke={stroke} />
        </svg>
    )
}

export default LayersIcon