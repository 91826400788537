import React, { useState } from 'react'
import editIcon from '@/images/edit.png'
import deleteIcon from '@/images/delete.png'
import Sidebar from './Sidebar';
import { useQueryClient } from 'react-query';
import { StatusResponseEnum } from '@/api';
import { BookingItem } from '@/api/bookings';
import Pagination from '@/ui/components/Pagination/Pagination';
import { Grid } from '@/components/Grid';
import { confirmDialog } from '@/components/ConfirmDialog';
import { BookingService } from '@/api/services/booking.service';
import { useProject } from '@/hooks/useProject';
import { useToast } from '@/components/shared/toast/useToast';
import { addMinutes, format, parseISO, startOfWeek } from 'date-fns';
import { useBookings } from '@/api/hooks/useBookings';
import { RolesEnum, useUserStore } from '@/stores/userStore';
import styled, { css } from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import { bookingDialog } from '../shared/booking/form/BookingModal';
import { extractGaps } from '@/utils/helpers/dates.helpers';

const Bookings = () => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const { data } = useBookings(currentPage, 20)
    const { role } = useUserStore()

    console.log(data);
    

    const isAdmin = role === RolesEnum.Admin
    const template = isAdmin ? '2fr 2fr 1fr 3fr 90px' : '2fr 1fr 3fr 90px'

    return (
        <Sidebar>
            <Sidebar.Header showAdd onAdd={() => bookingDialog({})} title="Брони" />

            <Grid>
                <Grid.RowHeader $cols={template}>
                    {isAdmin && <Grid.Item>ФИО</Grid.Item>}
                    <Grid.Item>Место</Grid.Item>
                    <Grid.Item>Тип</Grid.Item>
                    <Grid.Item>Дата</Grid.Item>
                    <Grid.Item />
                </Grid.RowHeader>

                {!data ? (
                    <div>Не найдено</div>
                ) : data?.items.slice(0, 20).map(item => <ElementItem key={item.id} isAdmin={isAdmin} item={item} />)}

            </Grid>

            <Pagination total={data?.total || 0} currentPage={currentPage} itemsPerPage={20} handlePageChange={setCurrentPage} />

        </Sidebar>
    )
}

export default Bookings

const ElementItem: React.FC<{ item: BookingItem, isAdmin: boolean }> = ({ item, isAdmin }) => {
    const bookingType = item.rec != '0' ? 'recurrent' : item.end ? 'common' : 'constant'
    const queryClient = useQueryClient()
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()
    const template = isAdmin ? '2fr 2fr 1fr 3fr 90px' : '2fr 1fr 3fr 90px'

    const removeBooking = async () => {
        try {
            const response = await BookingService.removeBooking({ workspaceId, projectId, bookingId: Number(item.id) })

            if (response && response.data.status === StatusResponseEnum.Success) {
                queryClient.refetchQueries(['bookings'])
                enqueueToast({ title: 'Успешно', message: 'Бронирование удалено!' }, { variant: 'success' })
            }
        } catch (e) {
            enqueueToast({ title: 'Ошибка', message: 'Не удалось удалить бронирование' }, { variant: 'error' })
        }
    }

    return (
        <Grid.Row $cols={template}>
            {isAdmin && <Grid.Item>{item.user}</Grid.Item>}
            <Grid.Item>{item.name}</Grid.Item>
            <Grid.Item>{bookingTypeTranslate[bookingType]}</Grid.Item>
            <Grid.Item>
                <ErrorBoundary
                    fallback={<div>Ошибка отображения даты</div>}
                >
                    <BookingDate type={bookingType} gap={item.gap} date={{ start: item.start, end: item.end }} />
                </ErrorBoundary>
            </Grid.Item>
            <Grid.Item style={{ display: "flex", alignItems: "flex-start", justifyContent: "space-around" }}>
                <div style={{ cursor: 'pointer' }}>
                    <img onClick={() => bookingDialog({ bookingId: Number(item.id) })} src={editIcon} alt="edit icon" />
                </div>
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                        return confirmDialog({
                            title: 'Удалить бронирование?',
                            message: 'Вы уверены, что хотите удалить бронирование?',
                            onSubmit: removeBooking
                        })
                    }}
                >
                    <img src={deleteIcon} alt="edit icon" />
                </div>
            </Grid.Item>
        </Grid.Row>
    )
}

const bookingTypeTranslate = {
    'common': 'Обычная',
    'recurrent': 'Еженедельная',
    'constant': 'Постоянная',
}

type BookingDateProps = {
    type: "common" | "recurrent" | "constant"
    gap: string
    date: {
        start: string
        end: string
    }
}

const d = new Date()
const offset = d.getTimezoneOffset()

const BookingDate: React.FC<BookingDateProps> = ({ type, date, gap }) => {

    const [open, setOpen] = useState(false)

    if (type === 'recurrent') {
        const schedule = extractSchedule(gap.split(','))

        return (
            <div>
                <ScheduleWrapper $expandable={true} $expanded={open} onClick={() => setOpen(!open)}>
                    {`${format(parseISO(date.start + '.000Z'), 'dd.MM.yyyy HH:mm')} - ${format(parseISO(date.end + '.000Z'), 'dd.MM.yyyy HH:mm')}`}
                </ScheduleWrapper>
                <ScheduleContent $expanded={open}>
                    {schedule.map(slot => (
                        <p key={slot.start.toISOString()}>
                            <b>{weekdays[new Date(slot.start).getDay()]}</b> {format(addMinutes(slot.start, -offset), 'HH:mm')} - {format(addMinutes(slot.end, -offset), 'HH:mm')}
                        </p>
                    ))}
                </ScheduleContent>
            </div>
        )
    }

    return (
        <div>
            <ScheduleWrapper>
                {format(parseISO(date.start + '.000Z'), 'dd.MM.yyyy HH:mm')}{type !== 'constant' ? ` - ${format(parseISO(date.end + '.000Z'), 'dd.MM.yyyy HH:mm')}` : ''}
            </ScheduleWrapper>
        </div>
    )
}

const weekdays = {
    1: 'Пн.',
    2: 'Вт.',
    3: 'Ср.',
    4: 'Чт.',
    5: 'Пт.',
    6: 'Сб.',
    0: 'Вс.',
}

const interval = 30 * 60 * 1000

const extractSchedule = (gap: string[]) => {
    const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 })
    const gaps = extractGaps(gap)

    return gaps.map(slot => ({
        start: new Date(weekStart.getTime() + 30 * 60 * 1000 * slot[0]),
        end: new Date(weekStart.getTime() + interval * Number(slot[1]) + interval)
    }))
}

const ScheduleWrapper = styled.div<{ $expandable?: boolean, $expanded?: boolean }>`
    padding-right: 20px;
    position: relative;

   ${({ $expandable, $expanded }) => $expandable && css`
        cursor: pointer;

        &::after {
            content: ' ';
            position: absolute;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='1' width='1.41421' height='8.48527' transform='rotate(-45 0 1)' fill='%23FDFDFD'/%3E%3Crect x='11' width='1.41421' height='8.48527' transform='rotate(45 11 0)' fill='%23FDFDFD'/%3E%3C/svg%3E%0A");
            width: 12px;
            height: 7px;
            background-size: cover;
            top: 2px;
            right: 5px;
            transition: all 0.5s;
            transform: ${$expanded ? 'rotate(180deg)' : 'rotate(0deg)'};
        }
    `}

    
`
const ScheduleContent = styled.div<{ $expanded?: boolean }>`
    height: auto;
    max-height: 0px;
    margin-top: 12px;
    transition: all 0.5s;
    overflow: hidden;

    ${({ $expanded }) => $expanded && css`
        max-height: 200px;
    `}

    p {
        &:not(:last-child) {
            margin-bottom: 6px;
        }
    }
`