import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { useParallelBooking } from '@/api/hooks/useParallelBooking';
import { useFormikContext } from 'formik';
import { useWeek } from '@/hooks/useWeek'
import { useEffect } from 'react';
import { useBooking } from '@/api/hooks/useBooking';
import { useBookingDialogStore } from '../../BookingModal';
import { convertToPeriods } from '@/api/bookings';
import { areIntervalsOverlapping, endOfWeek, startOfWeek } from 'date-fns';
import { useBookingStore } from '@/stores/bookingStore';
import { extractGaps, generateGap, isDateInRange } from '@/utils/helpers/dates.helpers';
import { bookingInterval } from '@/utils/constants/booking.constants';

export type BookingSlot = {
    start: Date
    end: Date
}

type GridCalendarProps = {
    addItem: Function
}

const getActualGaps = (slots, weekStart, weekEnd) => {
    if (!slots) return []

    return slots.filter(v => v.start && v.end).filter(slot => areIntervalsOverlapping(
        { start: slot.start, end: slot.end },
        { start: weekStart, end: weekEnd }
    ))
}

const GridCalendar: React.FC<GridCalendarProps> = ({
    addItem,
}) => {
    const { bookingId } = useBookingDialogStore()
    const { week } = useBookingStore()
    const weekStart = startOfWeek(week, { weekStartsOn: 1 })
    const weekEnd = endOfWeek(week, { weekStartsOn: 1 })
    const { values, setFieldValue } = useFormikContext<any>()
    const { data: current } = useBooking(bookingId)
    const { data, isLoading } = useParallelBooking({ nodeId: values.seat.id, userId: values.user.id, weekStart, weekEnd })

    // grid state
    const [selection, setSelection] = useState<any>({
        current: [],
        own: [],
        foreign: []
    })
    const [drag, setDrag] = useState<boolean>(false)
    const [deselect, setDeselect] = useState<boolean>(false)
    const [start, setStart] = useState<number | null>(null)
    const [end, setEnd] = useState<number | null>(null)

    const slots = values.dates
    const seat = values.seat.id

    // current booking slots
    const bookSlots = current?.slots.reduce((acc, slot) => acc.concat(convertToPeriods(slot.start, slot.end), weekStart), []) || []

    useEffect(() => {
        if (!data || !data.slots) return

        const predicate = slot => slot.user === Number(values.user.id)

        const weekStart = startOfWeek(week, { weekStartsOn: 1 })
        const weekEnd = endOfWeek(week, { weekStartsOn: 1 })
        // console.log(getActualGaps(slots, weekStart, weekEnd));
        

        const newSlots = {
            current: getActualGaps(slots, weekStart, weekEnd),
            own: getActualGaps(data.slots.filter(predicate), weekStart, weekEnd),
            foreign: getActualGaps(data.slots.filter(slot => !predicate(slot)), weekStart, weekEnd),
        }

        const currentGaps = newSlots.current.reduce((acc, slot) => acc.concat(convertToPeriods(slot.start, slot.end, weekStart)), [])

        const gaps = {
            current: currentGaps,
            own: newSlots.own.reduce((acc, slot) => acc.concat(convertToPeriods(slot.start, slot.end, weekStart)), []).filter(v => !bookSlots.includes(v)),
            foreign: newSlots.foreign.reduce((acc, slot) => acc.concat(convertToPeriods(slot.start, slot.end, weekStart)), []),
        }

        setSelection(gaps)

    }, [slots, week, seat, isLoading])


    const isFilled = (id) => {
        if (!start || !end) return false
        const arr = [start, end].sort((a, b) => a - b)
        return id >= arr[0] && id <= arr[1]
    }




    function dragStartHandler(e) {
        const id = e.target.dataset.id
        const { own, current, foreign } = selection

        const isSelected = current.includes(Number(id))

        if (isSelected) {
            setDeselect(true)
        }

        const isMyBooking = own.includes(Number(id))
        const isOtherBooking = foreign.includes(Number(id))

        const isExist = isMyBooking || isOtherBooking

        if (id && !isExist) {
            setDrag(true)
            setStart(id)
            setEnd(id)
        }
    }

    function dragOverHandler(e) {
        if (!drag) return

        const id = e.target.dataset.id
        if (id) {
            setEnd(id)
        }
    }

    function dragLeaveHandler(e) {
        setDrag(false)

        if (start && end) {
            const arr = [start, end].sort((a, b) => a - b)

            setStart(null)
            setEnd(null)
            let revalidation

            if (deselect) {
                const gap = generateGap(arr)
                revalidation = selection.current.filter(cell => !gap.includes(cell))
               
            } else {
                const gap = generateGap(arr)
                const { current, foreign } = selection
                const filtered = gap.filter(item => !(current.includes(item) || foreign.includes(item)))
                revalidation = selection.current.concat(filtered)
                
            }

            revalidate(revalidation)
        }

        setDeselect(false)
    }


    const revalidate = (revalidate) => {
        const activeSlots = slots.filter(slot => !isDateInRange({ start: slot.start, end: slot.end}, { start: weekStart, end: weekEnd }))
        const newSlots = extractGaps(revalidate.sort((a, b) => a - b))

        const timeSlots = newSlots.map(slot => ({
            start: new Date(weekStart.getTime() + 30 * 60 * 1000 * slot[0]),
            end: new Date(weekStart.getTime() + bookingInterval * Number(slot[1]) + bookingInterval)
        }))

        setFieldValue('dates', [...timeSlots])
    }





    return (
        <CalendarWrapper>
            <GridDays>
                <GridDay>Пн</GridDay>
                <GridDay>Вт</GridDay>
                <GridDay>Ср</GridDay>
                <GridDay>Чт</GridDay>
                <GridDay>Пт</GridDay>
                <GridDay>Сб</GridDay>
                <GridDay>Вс</GridDay>
            </GridDays>
            <Calendar
                onMouseDown={e => dragStartHandler(e)}
                onMouseUp={e => dragLeaveHandler(e)}
                onMouseMove={e => dragOverHandler(e)}
            >
                {Array.from({ length: 48 * 7 }).map((item, idx) => {
                    const filled = isFilled(idx)
                    const isMyBooking = selection.own.map(item => item).includes(idx)
                    const isSelected = selection.current.includes(idx) || filled
                    const isOtherBooking = selection.foreign.map(item => item).includes(idx)
                    const isGapFilled = isMyBooking || isSelected || isOtherBooking
                    const color = isSelected ? "#044D8C" : isOtherBooking ? "#AE0000" : "#F8DC4D"

                    return (
                        <GridItem
                            key={idx}
                            // data-time={idx * 30 * 60 * 1000 + week.getTime()}
                            data-type={true}
                            data-id={idx}
                            $color={deselect && filled ? 'rgba(0,0,0,0.25)' : color}
                            $filled={isGapFilled}
                        // $filled={isFilled(idx)}
                        />
                    )
                })}
            </Calendar>
        </CalendarWrapper>
    )
}

export default GridCalendar

const GridDays = styled.div`
    display: grid;
    row-gap: 6px;
    width: 42px;
    grid-template-rows: repeat(7, 1fr);
`

const CalendarWrapper = styled.div`
    display: grid;
    grid-template-columns: 24px 1fr;
`

const Calendar = styled.div`
    display: grid;
    row-gap: 6px;
    grid-template-columns: repeat(48, 1fr);
    z-index: 11;
`

const GridDay = styled.div`
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
    display: flex;
    align-items: center;
`

const GridItem = styled.div<{ $filled?: boolean, $color?: string }>`
    width: 13px;
    height: 13px;
    background: rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    cursor: pointer;
    user-select: none;
    transition: transform 0.3s;
    
    &:hover {
        transform: scale(1.5);
    }

    ${({ $filled, $color }) => $filled && $color && css`
        background: ${$color};
    `}
`