import Close from '@/components/Close';
import Grid from '@/components/Grid';
import Modal from '@/components/Modal/Modal';
import React from 'react';
import styled from 'styled-components'
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { API } from '@/api';
import { useGlobalStore } from '@/stores/globalStore';

const format = (str: string) => str || '-'

const LayerInfoModal: React.FC = () => {
    const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
    const { layerModal, setLayerModal } = useGlobalStore()
    const { data } = useQuery(
        'layer_info',
        () => API.layer.getLayerInfo({ workspaceId: Number(workspaceId), projectId: Number(projectId), layerId: Number(layerModal) }),
        { enabled: !!(workspaceId && projectId && layerModal) }
    )

    const onClose = () => setLayerModal(null)

    return (
        <Modal
            isOpen={Boolean(layerModal)}
            onClose={onClose}
            maxWidth={540}
        >
            <Header>
                <Title>Информация об уровне</Title>
                <Close color="#000" onClick={onClose} />
            </Header>

            <div>
                {data?.layer_info && (
                    <Grid>
                        <Grid.Row $cols="1fr 3fr">
                            <Grid.Item $bold>Наименование</Grid.Item>
                            <Grid.Item>{format(data.layer_info.name)}</Grid.Item>
                        </Grid.Row>
                        <Grid.Row $cols="1fr 3fr">
                            <Grid.Item $bold>Тип</Grid.Item>
                            <Grid.Item>{format(data.layer_info.type_uid)}</Grid.Item>
                        </Grid.Row>
                        <Grid.Row $cols="1fr 3fr">
                            <Grid.Item $bold>Расположение</Grid.Item>
                            <Grid.Item>{format(data.layer_info.parent_name.join('. '))}</Grid.Item>
                        </Grid.Row>
                        <Grid.Row $cols="1fr 3fr">
                            <Grid.Item $bold>Описание</Grid.Item>
                            <Grid.Item>{format(data.layer_info.info)}</Grid.Item>
                        </Grid.Row>
                    </Grid>
                )}

                <ControlsWrapper>
                    <CloseButton onClick={onClose}>Закрыть</CloseButton>
                </ControlsWrapper>
            </div>
        </Modal>
    )
}

export default LayerInfoModal

const ControlsWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const CloseButton = styled.button`
    outline: none;
    border: none;
    cursor: pointer;
    height: 40px;
    background: #BFBFBF;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.2rem 3.6rem;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`