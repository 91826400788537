import CalendarIcon from '@/components/icons/CalendarIcon';
import ElementsIcon from '@/components/icons/ElementsIcon';
import EmployeeIcon from '@/components/icons/EmployeeIcon';
import LayersIcon from '@/components/icons/LayersIcon';
import React from 'react'
import styled from 'styled-components'
import MenuItem from './MenuItem';
import Avatar from '@/ui/components/Avatar';
import { RolesEnum, useUserStore } from '@/stores/userStore';

const Menu: React.FC = () => {
    const { role } = useUserStore()

    return (
        <Wrapper>
            <Container>
                <MenuItem name="Уровни" selector="layers" icon={LayersIcon} />
                <MenuItem name="Брони" selector="bookings" icon={CalendarIcon} />
                <MenuItem name="Сотрудники" selector="employees" icon={EmployeeIcon} />
                <MenuItem name="Объекты" selector="elements" icon={ElementsIcon} />
                {role === RolesEnum.Admin && <MenuItem name="Отчеты" selector="reports" icon={Avatar} />}
            </Container>
        </Wrapper>
    )
}

export default Menu

const Wrapper = styled.div`
    width: 90px;
    box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    transition: all 0.5s;
    position: absolute;
    top: 50%;
    right: -90px;
    transform: translateY(-50%);
    border-left: 1px solid ${props => props.theme.palette.text.primary};
    background: ${props => props.theme.palette.background.primary};
    color: ${props => props.theme.palette.text.primary};
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`