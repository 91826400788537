import React from 'react'
import styled, { css } from 'styled-components'

export const SearchPlacesIcon = ({ active, onClick, ...otherProps }) => {
    return (
        <SvgContainer $active={active} onClick={onClick} {...otherProps}>
            <svg width="32" height="28" viewBox="0 0 32 28" fill="none">
                <path d="M13.0664 12.1198C13.4815 11.8895 13.9707 11.8349 14.3991 11.9711L28.0822 16.3224C29.246 16.6925 29.3514 18.1655 28.2635 18.8538L20.0651 24.0403C19.6344 24.3129 19.1055 24.3876 18.6447 24.2411L4.23177 19.6577C3.02542 19.2741 2.96925 17.723 4.13821 17.0743L13.0664 12.1198Z" fill="#FA8164" stroke="#FAFAFA" />
                <path d="M16.7662 18.4391C16.6142 18.2605 16.4216 18.0305 16.1998 17.7572C15.6335 17.0595 14.8793 16.0829 14.126 14.9664C13.3718 13.8486 12.6247 12.5996 12.0677 11.3563C11.5078 10.1065 11.1569 8.89925 11.1569 7.85492C11.1569 4.75348 13.6647 2.24561 16.7662 2.24561C19.8676 2.24561 22.3755 4.75348 22.3755 7.85492C22.3755 8.89925 22.0245 10.1065 21.4646 11.3563C20.9076 12.5996 20.1606 13.8486 19.4063 14.9664C18.6531 16.0829 17.8988 17.0595 17.3325 17.7572C17.1108 18.0305 16.9182 18.2605 16.7662 18.4391ZM14.8698 9.7513C15.3727 10.2543 16.0549 10.5368 16.7662 10.5368C17.4775 10.5368 18.1596 10.2543 18.6626 9.75131C19.1655 9.24835 19.4481 8.5662 19.4481 7.85492C19.4481 7.14363 19.1655 6.46148 18.6626 5.95853C18.1596 5.45558 17.4775 5.17302 16.7662 5.17302C16.0549 5.17302 15.3727 5.45558 14.8698 5.95853C14.3668 6.46148 14.0843 7.14363 14.0843 7.85492C14.0843 8.5662 14.3668 9.24835 14.8698 9.7513Z" fill="#FA8164" stroke="#FAFAFA" />
            </svg>
        </SvgContainer>
    )
}

export const SearchUsersIcon = ({ active, onClick, ...otherProps }) => {
    return (
        <SvgContainer $active={active} onClick={onClick} {...otherProps}>
            <svg width="26" height="23" viewBox="0 0 26 23" fill="none">
                <path d="M0.543335 19.8337C0.543335 18.1125 2.25137 15.1572 7.77923 15.1572C13.3071 15.1572 15.0151 18.1125 15.0151 19.8337V22.4396H0.543335V19.8337Z" fill="#FA8164" stroke="#FAFAFA" />
                <path d="M11.6306 8.96304C11.6306 11.2926 9.87467 13.1219 7.77918 13.1219C5.68368 13.1219 3.92773 11.2926 3.92773 8.96304C3.92773 6.63348 5.68368 4.8042 7.77918 4.8042C9.87467 4.8042 11.6306 6.63348 11.6306 8.96304Z" fill="#FA8164" stroke="#FAFAFA" />
                <path d="M6.51758 19.1263C6.51758 16.9335 8.68362 13.271 15.5151 13.271C22.3465 13.271 24.5126 16.9335 24.5126 19.1263V22.4394H6.51758V19.1263Z" fill="#FA8164" stroke="#FAFAFA" />
                <path d="M20.3574 5.78028C20.3574 8.69576 18.1578 11 15.5151 11C12.8723 11 10.6727 8.69576 10.6727 5.78028C10.6727 2.86481 12.8723 0.560547 15.5151 0.560547C18.1578 0.560547 20.3574 2.86481 20.3574 5.78028Z" fill="#FA8164" stroke="#FAFAFA" />
            </svg>
        </SvgContainer>
    )
}

export const SearchLayersIcon = ({ active, onClick, ...otherProps }) => {
    return (
        <SvgContainer $active={active} onClick={onClick} {...otherProps}>
            <svg width="31" height="26" viewBox="0 0 31 26" fill="none">
                <path d="M12.3756 10.9923C12.7908 10.7619 13.2799 10.7073 13.7084 10.8435L26.4689 14.9015C27.6327 15.2715 27.7382 16.7445 26.6502 17.4329L19.0863 22.218C18.6555 22.4906 18.1267 22.5653 17.6659 22.4188L4.23195 18.1467C3.0256 17.7631 2.96942 16.212 4.13839 15.5633L12.3756 10.9923Z" fill="#FA8164" stroke="#FAFAFA" />
                <path d="M12.3755 7.28083C12.7907 7.05045 13.2798 6.99587 13.7082 7.13211L26.4688 11.19C27.6326 11.5601 27.738 13.0331 26.6501 13.7214L19.0862 18.5066C18.6554 18.7791 18.1266 18.8539 17.6658 18.7073L4.23182 14.4353C3.02547 14.0516 2.9693 12.5005 4.13826 11.8519L12.3755 7.28083Z" fill="#FA8164" stroke="#FAFAFA" />
                <path d="M12.3755 3.59724C12.7907 3.36686 13.2798 3.31227 13.7082 3.44852L26.4688 7.50644C27.6326 7.87653 27.738 9.34953 26.6501 10.0378L19.0862 14.823C18.6554 15.0955 18.1266 15.1703 17.6658 15.0237L4.23182 10.7517C3.02547 10.3681 2.9693 8.81695 4.13826 8.16827L12.3755 3.59724Z" fill="#FA8164" stroke="#FAFAFA" />
            </svg>
        </SvgContainer>
    )
}

export const SearchBookingsIcon = ({ active, onClick, ...otherProps }) => {
    return (
        <SvgContainer $active={active} onClick={onClick} {...otherProps}>
            <svg width="20" height="20" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_425_375)">
                    <path d="M6.25 0.78125C6.25 0.57405 6.16769 0.375336 6.02118 0.228823C5.87466 0.08231 5.67595 0 5.46875 0C5.26155 0 5.06284 0.08231 4.91632 0.228823C4.76981 0.375336 4.6875 0.57405 4.6875 0.78125V1.5625H3.125C2.2962 1.5625 1.50134 1.89174 0.915291 2.47779C0.32924 3.06384 0 3.8587 0 4.6875L0 6.25H25V4.6875C25 3.8587 24.6708 3.06384 24.0847 2.47779C23.4987 1.89174 22.7038 1.5625 21.875 1.5625H20.3125V0.78125C20.3125 0.57405 20.2302 0.375336 20.0837 0.228823C19.9372 0.08231 19.7385 0 19.5312 0C19.324 0 19.1253 0.08231 18.9788 0.228823C18.8323 0.375336 18.75 0.57405 18.75 0.78125V1.5625H6.25V0.78125ZM25 21.875V7.8125H0V21.875C0 22.7038 0.32924 23.4987 0.915291 24.0847C1.50134 24.6708 2.2962 25 3.125 25H21.875C22.7038 25 23.4987 24.6708 24.0847 24.0847C24.6708 23.4987 25 22.7038 25 21.875ZM16.9594 13.8344L12.2719 18.5219C12.1993 18.5946 12.1131 18.6524 12.0182 18.6917C11.9233 18.7311 11.8215 18.7514 11.7188 18.7514C11.616 18.7514 11.5142 18.7311 11.4193 18.6917C11.3244 18.6524 11.2382 18.5946 11.1656 18.5219L8.82187 16.1781C8.67518 16.0314 8.59276 15.8325 8.59276 15.625C8.59276 15.4175 8.67518 15.2186 8.82187 15.0719C8.96857 14.9252 9.16754 14.8428 9.375 14.8428C9.58246 14.8428 9.78143 14.9252 9.92813 15.0719L11.7188 16.8641L15.8531 12.7281C15.9998 12.5814 16.1988 12.499 16.4062 12.499C16.6137 12.499 16.8127 12.5814 16.9594 12.7281C17.1061 12.8748 17.1885 13.0738 17.1885 13.2812C17.1885 13.4887 17.1061 13.6877 16.9594 13.8344Z" fill="#FA8164" />
                </g>
                <defs>
                    <clipPath id="clip0_425_375">
                        <rect width="25" height="25" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgContainer>
    )
}

const SvgContainer = styled.div<{ $active?: boolean }>`
    cursor: pointer;

    &:hover {
        opacity: 0.7;
    }

    ${({ $active }) => $active ? css`
        opacity: 1 !important;
    ` : css`
        opacity: 0.5;
    `}
`