import Grid from '@/ui/components/Grid'
import React from 'react'
import styled, { css } from 'styled-components'
import WeekSelector from './WeekSelector'
import { useFormikContext } from 'formik';
import { useWeek } from '@/hooks/useWeek'
import GridAnnotation from './GridAnnotation';
import GridCalendar from './calendar-selector/GridCalendar';

export type BookingSlot = {
    start: Date
    end: Date
}

type BookingGridProps = {
    bookings: any
    addItem: Function
}

const BookingGridSelector: React.FC<BookingGridProps> = ({
    addItem,
}) => {
    const { values } = useFormikContext<any>()

    return (
        <>
            <WeekSelector visible={values.type !== '2'} />

            <CssGrid>
                <GridCols>
                    <div />
                    {Array.from({ length: 24 }).map((item, idx) => (
                        <GridTime key={idx}>
                            {`${idx}`.padStart(2, '0')}:00
                        </GridTime>
                    ))}
                </GridCols>
                <GridCalendar addItem={addItem} />
                <Grid container>
                    <Grid item xs={12} md={3}>
                        <GridAnnotation color="#AE0000" text="Занято другим сотрудником" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <GridAnnotation color="#F8DC4D" text="Другая моя бронь" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <GridAnnotation color="#044D8C" text="Текущая бронь" />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <GridAnnotation color="#BFBFBF" text="Свободные" />
                    </Grid>
                </Grid>
            </CssGrid>
        </>
    )
}

export default BookingGridSelector

const CssGrid = styled.div`
    display: grid;
    row-gap: 6px;
    grid-template-rows: 42px 1fr;
`

const GridCols = styled.div`
    display: grid;
    grid-template-columns: 24px repeat(24, 1fr);

`
const GridTime = styled.div`
    font-size: 1rem;
    line-height: 1rem;
    color: #000000;
    display: inline-block;
    white-space: nowrap;
    transform: translate(0,100%) rotate(-90deg);
    transform-origin: top left;
`