import { useQuery } from 'react-query';
import { useToast } from '@/components/shared/toast/useToast';
import { useProject } from '@/hooks/useProject';
import { BookingService } from '../services/booking.service';

export const useBookings = (page: number, perPage?: number) => {
    const { workspaceId, projectId } = useProject()
    const { enqueueToast } = useToast()

    const { data, isLoading, refetch } = useQuery(
        ['bookings', workspaceId, projectId, page],
        () => BookingService.getBookingList({ workspaceId, projectId, page: page - 1, perPage }),
        {
            enabled: !!workspaceId && !!projectId && !!page,
            select: ({ data }) => ({ items: data.items, total: data.total }),
            onError: () => {
                enqueueToast({ title: 'Ошибка!', message: 'Не удалось загрузить список бронирований' }, { variant: 'error' })
            }
        }
    )

    return { data, isLoading, refetch }
}