import React from 'react'
import { useTheme } from 'styled-components'

const ElementsIcon = () => {
    const { menu: { color, stroke } } = useTheme()

    return (
        <svg width="42" height="36" viewBox="0 0 42 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.4135 15.8649C17.8287 15.6345 18.3178 15.5799 18.7463 15.7162L37.7174 21.7491C38.8812 22.1192 38.9867 23.5922 37.8987 24.2805L26.0637 31.7677C25.6329 32.0402 25.1041 32.115 24.6433 31.9684L4.61849 25.6005C3.41214 25.2168 3.35597 23.6657 4.52493 23.0171L17.4135 15.8649Z" fill={color} stroke={stroke} />
            <path d="M21.9985 24.4905C21.7886 24.246 21.4994 23.9031 21.1563 23.4803C20.4245 22.5786 19.4495 21.3163 18.4755 19.8727C17.5006 18.4277 16.5328 16.8102 15.8103 15.1974C15.0849 13.5781 14.6235 12.0004 14.6235 10.625C14.6235 6.54739 17.9209 3.25 21.9985 3.25C26.0761 3.25 29.3735 6.54739 29.3735 10.625C29.3735 12.0004 28.9122 13.5781 28.1868 15.1974C27.4643 16.8102 26.4965 18.4277 25.5216 19.8727C24.5476 21.3163 23.5726 22.5786 22.8408 23.4803C22.4977 23.9031 22.2085 24.246 21.9985 24.4905ZM19.6562 12.9673C20.2775 13.5885 21.12 13.9375 21.9985 13.9375C22.8771 13.9375 23.7196 13.5885 24.3408 12.9673C24.962 12.3461 25.311 11.5035 25.311 10.625C25.311 9.74647 24.962 8.90392 24.3408 8.28271C23.7196 7.66149 22.8771 7.3125 21.9985 7.3125C21.12 7.3125 20.2775 7.66149 19.6562 8.28271C19.035 8.90392 18.686 9.74647 18.686 10.625C18.686 11.5035 19.035 12.3461 19.6562 12.9673Z" fill={color} stroke={stroke} />
        </svg>
    )
}

export default ElementsIcon