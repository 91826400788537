import { useParams, Redirect } from 'react-router-dom';
import { StatusResponseEnum } from '@/api/types';
import React, { useEffect } from 'react'
import { RolesEnum, useUserStore } from '@/stores/userStore';
import Preloader from '@/components/Preloader/Preloader';
import { useMe } from '@/api/hooks/useMe';
import { useMetadata } from '@/api/hooks/useMetadata';

function LoadingComponent() {
    return (
        <Preloader show={true} />
    )
}

export default function withAuthRedirect<WCP>(WrappedComponent: any) {
    const WithAuthRedirectWrapper = (props: any) => {
        const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
        const { setUser, setAuthenticated, setRole } = useUserStore()
        const { user } = useMe()
        const { metadata, isLoading, isIdle } = useMetadata()

        useEffect(() => {
            if (!user) return
            setUser(user)

            if (user.role === RolesEnum.Admin) {
                setRole(RolesEnum.Admin)
            }

            setAuthenticated(true)
        }, [user])

        if (isLoading || isIdle) {
            return <LoadingComponent />;
        }
        
        if (!metadata || !metadata?.data || metadata.status === StatusResponseEnum.NotAuthorized) {
            return <Redirect to={`/login?workspace_id=${workspaceId}&project_id=${projectId}`} />
        }

        return <WrappedComponent {...props as WCP} />;
    };

    // @ts-ignore
    WithAuthRedirectWrapper.getLayout = WrappedComponent.getLayout || null

    return WithAuthRedirectWrapper;
}