import { FormLabel } from '@/ui/components/Form/FormLabel';
import Grid from '@/ui/components/Grid';
import React from 'react';
import styled, { css } from 'styled-components'
import { Field, FieldArrayRenderProps } from 'formik';
import RemoveIcon from '@/components/icons/RemoveIcon';
import AddIcon from '@/components/icons/AddIcon';
import { DatePickerField } from '@/ui/components/Field/DatePickerField';

type BookingIntervalProps = {
    name: string
    values: {
        [key: string]: any
    }
    arrayHelpers: FieldArrayRenderProps
}

const BookingInterval = ({ name, interval, index, count, helpers }) => {

    return (
        <Grid key={'dates ' + index} container>
            <Grid item xs={12} md={4}>
                <FormLabel>Дата и время начала</FormLabel>
                <Field name={`${name}[${index}].start`} $fullWidth placeholder="Время начала" component={DatePickerField} />
            </Grid>
            <Grid item xs={12} md={4}>
                <FormLabel>Дата и время окончания</FormLabel>
                <Field name={`${name}[${index}].end`} $fullWidth type="text" placeholder="Время окончания" component={DatePickerField} />
            </Grid>
            <Buttons>
                {count > 0 && (
                    <BtnWrapper type="button" onClick={() => helpers.remove(index)}>
                        <RemoveIcon />
                    </BtnWrapper>
                )}
                <BtnWrapper type="button" onClick={() => helpers.insert(index, { start: '', end: '' })}>
                    <AddIcon />
                </BtnWrapper>
            </Buttons>
        </Grid>
    )
}

export const NoBookingIntervals = ({ helpers }) => {
    return (
        <Grid container>
            <Grid item xs={12} md={12}>
                <AddButton type="button" onClick={() => helpers.push({ start: '', end: '' })}>
                    Добавить интервал
                </AddButton>
            </Grid>
        </Grid>
    )
}

//     state = {
//         startDate: new Date()
//     }

//     addItem = (index: number, value: any) => {
//         const { arrayHelpers } = this.props
//         arrayHelpers.insert(index, value)
//     }

//     render() {
//         const { name, values, arrayHelpers } = this.props;

        
//     }
// }

export default BookingInterval

const Buttons = styled.div`
    display: flex;
    margin-top: 1.6rem;
    justify-content: space-around;
    align-items: center;
`

const BtnWrapper = styled.button`
    outline: none;
    border: none;
    cursor: pointer;
    background: #F8DC4D;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s;

    &:not(:last-child) {
        margin-right: 8px;
    }

    &:hover {
        opacity: 0.7;
    }
`

const ButtonBaseStyles = css`
    border: none;
    outline: none;
    border-radius: 4px;
    font-size: 1.6rem;
    line-height: 1.6rem;
    padding: 1.2rem;
    cursor: pointer;
`

const AddButton = styled.button`
    ${ButtonBaseStyles};

    background: #F8DC4D;
    color: #044D8C;
`