import { Helmet } from 'react-helmet'
import React, { ReactChild } from 'react'
import styled from 'styled-components';
import DatePickerStyles from '@/ui/DatepickerStyles';

type BaseLayoutProps = {
    children: ReactChild | ReactChild[],
    title?: string,
    noIndex?: boolean
} 

const BaseLayout: React.FC<BaseLayoutProps> = ({ title, noIndex, children }) => {
    return (
        <>
            <Helmet>
                {title ? <title>{title} | КАРТЫ ОФИСА</title> : <title>КАРТЫ ОФИСА</title>}
                {noIndex && <meta name="robots" content="noindex" />}
                <meta charSet="utf-8" />
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"/>
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
                <link href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap" rel="stylesheet" />
            </Helmet>
            <RootWrapper>
                {children}
                <DatePickerStyles />
            </RootWrapper>
        </>
    )
}

export default BaseLayout

const RootWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background: #f2f3f8;
`