import Search, { SearchButton } from '@/components/AppBar/Search';
import Close from '@/components/Close';
import Grid from '@/components/Grid';
import Modal from '@/components/Modal/Modal';
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components'
import { FormikContextType } from 'formik';
import { useQuery } from 'react-query';
import { API } from '@/api';
import { useParams } from 'react-router-dom';
import { UserData } from '@/api/user';
import Pagination from '@/ui/components/Pagination/Pagination';
import { Input } from '@/ui/components/Field/Input';
import { debounce } from 'lodash';
import { useUsers } from '@/api/hooks/useUsers';
import { useUsersListerFields } from '@/api/hooks/useUsersListerFields';
import FormLoader from '@/components/ui/form/FormLoader';
import { FormControls } from '../BokingForm';
import { PrimaryButton } from '@/components/ui/button/PrimaryButton';
import { DefaultButton } from '@/components/ui/button/DefaultButton';
import { makeCols } from '@/components/Employee/Employees';
import { UserExtra } from '@/components/Employee/makeEmployeeData';

type ReportModalProps = {
    isOpen: boolean
    onClose: () => void
    setFieldValue: any
}

const SelectUserModal: React.FC<ReportModalProps> = ({ isOpen, onClose, setFieldValue }) => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [user, setUser] = useState<any>(null)
    const [name, setName] = useState<string>('')

    const { data, isLoading } = useUsers({ page: currentPage, perPage: 20, name })
    const { data: extra } = useUsersListerFields()

    const handleSelection = async () => {
        if (user) {
            setFieldValue('user', {
                id: user.value,
                name: user.label
            })
        }
        onClose()
    }

    const handleNameChange = (e) => setName(e?.target?.value)

    const debouncedNameResponse = useMemo(() => {
        return debounce(handleNameChange, 500)
    }, [])

    useEffect(() => {
        return () => debouncedNameResponse.cancel()
    }, [])

    useEffect(() => {
        if (isOpen) {
            setUser(null)
        }
    }, [isOpen])

    const columns = makeCols(extra?.fields)
    const users = extractFields(data?.items, columns)
    const template = `20px 1fr ${columns.map(() => `1fr`).join(' ')}`

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            maxWidth={700}
        >
            <Header>
                <Title>Выбор сотрудника</Title>
                <Close color="#000" onClick={onClose} />
            </Header>
            <div>
                <Grid>
                    <Filters>
                        <FiltersItem>
                            <Input
                                $fullWidth
                                placeholder='Поиск...'
                                onChange={debouncedNameResponse}
                            />
                        </FiltersItem>
                        <FiltersSearch>
                            <SearchButton />
                        </FiltersSearch>
                    </Filters>

                    <Grid.RowHeader $cols={template}>
                        <Grid.Item />
                        <Grid.Item>ФИО</Grid.Item>
                        {columns.map(column => <Grid.Item key={column.uid}>{column.label}</Grid.Item>)}
                    </Grid.RowHeader>

                    {data && data.items && data?.items.length ? (
                        <>
                            {users.map(user => <EmployeeItem key={user.id} user={user} template={template} handleSelection={setUser} />)}
                            <PaginationWrapper>
                                <Pagination currentPage={currentPage} total={data.total} itemsPerPage={20} handlePageChange={setCurrentPage} />
                            </PaginationWrapper>
                        </>
                    ) : <NotFound>Ничего не найдено</NotFound>}

                    <FormLoader isLoading={isLoading} />

                    <FormControls>
                        <PrimaryButton type="submit" onClick={handleSelection}>Выбрать</PrimaryButton>
                        <DefaultButton onClick={onClose} type="button">Отменить</DefaultButton>
                    </FormControls>
                </Grid>
            </div>
        </Modal>
    )
}

export default SelectUserModal

const EmployeeItem: React.FC<{ user: any, template: string, handleSelection: React.Dispatch<any> }> = ({ user, template, handleSelection }) => {

    return (
        <Grid.Row $cols={template} as="label" $label style={{ padding: '1rem 0' }} onClick={() => handleSelection({ label: user.display, value: user.id })}>
            <Grid.Item>
                <input type="radio" name="employee" value={user.id} />
            </Grid.Item>
            <Grid.Item>{user.display}</Grid.Item>
            {user.data.map((prop, idx) => <Grid.Item key={idx}>{prop}</Grid.Item>)}
            {/* TODO: Отсутствуют данные ? */}
            {/* <Grid.Item>Не указано</Grid.Item>
            <Grid.Item>Не указано</Grid.Item> */}
        </Grid.Row>
    )
}

const extractFields = (data: any[] | undefined, columns: UserExtra[] | undefined) => {
    if (!data || !columns) return []

    return data.map((user) => {
        const userData: any = []

        columns.forEach(col => {
            userData.push(user[col.uid] || '')
        })

        return { id: Number(user.id), display: user.display, data: userData}
    })
}

const NotFound = styled.div`
    width: 100%;
    text-align: center;
    padding: 12px 0;
`

const Filters = styled.div`
    display: grid;
    grid-template-columns: 1fr 60px;
    column-gap: 14px;
    padding: 6px 0 14px 0;
`
const FiltersSearch = styled.div`
`
const FiltersItem = styled.div`
`


const PaginationWrapper = styled.div`
    padding: 2rem 0 0;
`

const SearchEmployeeWrapper = styled.div`
    padding-bottom: 1rem;
`

const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const Title = styled.div`
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.4rem;
  color: #000000;
`