import React, { useMemo, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import Sidebar from '@/components/Sidebar/Sidebar';
import Grid from '@/components/Grid';
import { Input } from '@/ui/components/Field/Input';
import EmployeeListItem from './EmployeeListItem';
import Toolbar from '../Toolbar/Toolbar';
import { makeEmployeeData, UserExtra } from './makeEmployeeData';
import Pagination from '@/ui/components/Pagination/Pagination';
import { useUsers } from '@/api/hooks/useUsers';
import { debounce } from 'lodash';
import { useUsersListerFields } from '@/api/hooks/useUsersListerFields';
import { RuntimeFieldData } from '@/api/services/user.service';

export const makeCols = (fields: RuntimeFieldData[] | undefined) => {
    if (!fields) return []

    const extra: UserExtra[] = fields.map(field => ({
        uid: field.uid,
        accessor: field.alias,
        label: field.label,
    }))

    return extra
}

const Employees = () => {
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [filter, setFilter] = useState<string>('')

    const { data } = useUsers({ page: currentPage, perPage: 20, name: filter })
    const { data: extra } = useUsersListerFields()

    const handleChange = (e) => setFilter(e?.target?.value)
    const debouncedResponse = useMemo(() => {
        return debounce(handleChange, 500)
    }, [])

    useEffect(() => {
        return () => debouncedResponse.cancel()
    }, [])

    const columns = makeCols(extra?.fields)
    const users = makeEmployeeData(data?.items, columns)
    const template = `40px 1fr ${columns.map(() => `1fr`).join(' ')} 40px`

    return (
        <Sidebar>
            <Sidebar.Header title="Сотрудники" onAdd={() => alert('Добавление сотрудника')} />

            <Toolbar>
                <Toolbar.Item xs={12} md={4}>
                    <Toolbar.Label>Поиск по данным сотрудника</Toolbar.Label>
                    <Input $fullWidth placeholder="Поиск" onChange={debouncedResponse} />
                </Toolbar.Item>
            </Toolbar>

            <Grid style={{ minWidth: 800 }}>
                <Grid.RowHeader $cols={template}>
                    <Grid.Item>id</Grid.Item>
                    <Grid.Item>ФИО</Grid.Item>
                    {columns.map(column => <Grid.Item key={column.uid}>{column.label}</Grid.Item>)}
                    <Grid.Item />
                </Grid.RowHeader>

                {users.map(user => (
                    <EmployeeListItem
                        key={user.id}
                        template={template}
                        item={user}
                    />
                ))}
            </Grid>

            <Pagination currentPage={currentPage} total={data?.total || 0} handlePageChange={setCurrentPage} />
        </Sidebar>
    )
}

export default Employees