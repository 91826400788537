import { API, BASE_DOMAIN } from "@/api"
import { useGlobalStore } from "@/stores/globalStore"
import React, { useRef, useState, useEffect } from "react"
import { Group, Rect, Text } from "react-konva"
import useImage from "use-image"
import { getMapCoord } from "./Polygon"
import { animated, Spring } from '@react-spring/konva';
import { easePoly } from 'd3-ease';

export const defaultMapPointImage = {
    owner_id: "15",
    image_id: "2d95a0df350c4c2dae7ab2c0c03bf337",
    extension: "png"
}

export const getImageLink = (image) => {
    if (!image || !image.owner_id) return ''
    return `${BASE_DOMAIN}media/metablock/${image.owner_id}/${image.image_id}.SW100H100!default.${image.extension}`
}

const useMapAvatar = (bookingData, node) => {
    const imgSrc = bookingData ? API.user.getAvatar({ userId: Number(bookingData.user_id) }) : getImageLink(node?.icon) || getImageLink(defaultMapPointImage)
    const [pointImg] = useImage(imgSrc)

    return { image: pointImg }
}

export const isBookable = (data) => {
    if (!data) return false
    const plugin: any = Object.values(data).find((obj: any) => obj?.bookable)
    if (!plugin) return false
    return plugin.bookable
}

const Point = ({ point, width, height, pointOptions, metadata, bookings }) => {
    const { id, x, y, name, type_uid, plugin_data } = point
    const { labelSize, fontSize, color, borderWidth } = pointOptions
    const nodes = metadata?.metablock.nodes || {}
    const textRef = useRef(null)
    const node = nodes[type_uid]

    const avaliable = isBookable(plugin_data)

    const [pX, setPx] = useState(0)
    const [coord, setCoord] = useState({ x: 0, y: 0 })

    const bookingData = bookings?.find(book => book.point_id == id)
    const { image } = useMapAvatar(bookingData, node)

    const { setSeat, setSeatEmployee } = useGlobalStore()


    const onSelect = () => {
        setSeat(Number(point.id))
        if (bookingData) {
            setSeatEmployee(Number(bookingData.user_id))
        } else {
            setSeatEmployee(null)
        }
    }


    useEffect(() => {
        // @ts-ignore
        const pointX = (-textRef.current?.textWidth / 2) + (width * labelSize) / 2
        setPx(pointX)
        setCoord({
            x: getMapCoord(width, x),
            y: getMapCoord(height, y)
        })
    }, [textRef.current, image])

    if (!image) return null

    return (
        <Group
            x={coord.x}
            y={coord.y}
            offsetX={width * labelSize / 2}
            offsetY={width * labelSize / 2}
            onClick={onSelect}
        >
            <Group
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                opacity={avaliable ? 1 : 0.3}
                id={"point" + point.id}
            >
                <Rect
                    width={width * labelSize}
                    height={width * labelSize}
                    stroke={node.border}
                    strokeWidth={fontSize * borderWidth}
                    fill={node.background}
                    cornerRadius={node.radius}
                />
                <Rect
                    width={width * labelSize}
                    height={width * labelSize}
                    cornerRadius={node.radius}
                    fillPatternImage={image}
                    fillPatternScaleX={(width * labelSize) / image?.width}
                    fillPatternScaleY={(width * labelSize) / image?.width}
                />
            </Group>
            <Text
                ref={textRef}
                text={bookingData?.display || name}
                fontSize={fontSize}
                fill={color}
                y={width * 1.1 * labelSize}
                x={pX}
            />
        </Group>
    )
}

export const scaleRelativeToPoint = (stage, point, scale, onRescale) => {
    const scaleBy = 1.25;
    const oldScale = stage.scaleX()

    const mousePointTo = {
        x: point.x / oldScale - stage.x() / oldScale,
        y: point.y / oldScale - stage.y() / oldScale
    };

    const newScale = scale;
    const x = (point.x / newScale - mousePointTo.x) * newScale
    const y = (point.y / newScale - mousePointTo.y) * newScale


    onRescale({
        scale: newScale,
        point: {
            x,
            y
        }
    });
};


export const AnimatedPoint = (props) => {
    const { point } = props
    const { seat } = useGlobalStore()

    const currentSeat = seat == point.id

    return (
        <Spring
            from={{ opacity: 1 }}
            to={currentSeat ? [
                { opacity: 0.2 },
                { opacity: 1 },
                { opacity: 0.2 },
                { opacity: 1 },
            ] : { opacity: 1 }}
            config={{
                easing: easePoly.exponent(2)
            }}
        >
            {springProps => (
                // @ts-ignore
                <animated.Group {...springProps}>
                   <Point {...props} />
                </animated.Group>
            )}
        </Spring>
    )
}

export default React.memo(Point)

function onMouseEnterHandler(e) {
    const container = e.target.getStage()?.container()

    if (container) {
        container.style.cursor = "pointer"
    }

}

function onMouseLeaveHandler(e) {
    const container = e.target.getStage()?.container()

    if (container) {
        container.style.cursor = "default"
    }

}