import AppLayout from '../components/layout/AppLayout'
import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { API } from '../api'
import { useProjectStore } from '@/stores/projectStore'
import MapContainer from './MapContainer';
import { useGlobalStore } from '@/stores/globalStore';
import LayerInfoModal from '@/components/Sidebar/Layers/LayerInfoModal'
import { useLocation } from 'react-router-dom';
import { useLayers } from '@/api/hooks/useLayers';
import qs from 'query-string';
import { useMapStore } from '@/stores/mapStore'

const ProjectContainer: React.FC = () => {
    const router = useHistory()
    const location = useLocation()
    const { nodes, setNodes } = useProjectStore()
    const { setActiveLayer, activeLayer, setEmployee, employee, setSeat, seat } = useGlobalStore()
    const { setZoomSeat } = useMapStore()

    const { layers } = useLayers()
    const queryParams = qs.parse(location.search)
    const mapID = queryParams.map
    const employeeID = queryParams.employee
    const pointID = queryParams.point

    const { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
    const { data, isLoading, isRefetching } = useQuery(
        'metadata',
        () => API.projects.metadata({ workspaceId: Number(workspaceId), projectId: Number(projectId) })
    )
    // const node = useQuery(
    //     'node_list',
    //     () => API.projects.getNodeList({ workspaceId: Number(workspaceId), projectId: Number(projectId) }),
    //     { enabled: !!(workspaceId && projectId), refetchOnMount: false }
    // )
    // const users = useQuery(
    //     'users_list',
    //     () => API.user.list({ workspaceId: Number(workspaceId), projectId: Number(projectId) }),
    //     { enabled: !!(workspaceId && projectId), refetchOnMount: false }
    // )

    // const bookings = useQuery(
    //     'bookings',
    //     () => API.bookings.list({ workspaceId: Number(workspaceId), projectId: Number(projectId) }),
    //     { enabled: !!(workspaceId && projectId), refetchOnMount: false }
    // )

    useEffect(() => {
        if (layers) {
            if (layers.layer && !mapID) {
                setActiveLayer(Number(layers.layer.id))
            }
            setNodes(layers.nodes)
        }
    }, [layers])

    // useEffect(() => {
    //     const layer = nodes.find(node => node.parent === null)
    //     if (layer && layer.id && !mapId) {
    //         setActiveLayer(Number(layer.id))
    //     }
    // }, [nodes])

    useEffect(() => {
        if (mapID) {
            setActiveLayer(Number(mapID))
        }
        if (pointID) {
            setZoomSeat(Number(pointID))
        }
    }, [])

    useEffect(() => {
        if (employeeID) {
            setEmployee(Number(employeeID))
        }
    }, [employeeID])

    useEffect(() => {
        if (pointID) {
            setSeat(Number(pointID))
        }
    }, [pointID])

    // useEffect(() => {
    //     const newQueries = { ...queryParams, map: activeLayer }
        
    //     // if (!activeLayer) return
        
    //     router.replace({
    //         pathname: location.pathname,
    //         search: qs.stringify(newQueries, { sort: false, skipNull: true })
    //     })
    // }, [activeLayer])

    useEffect(() => {
        // if (!employee) return

        const newQueries = { ...queryParams, employee, point: null }

        router.replace({
            pathname: location.pathname,
            search: qs.stringify(newQueries, { sort: false, skipNull: true })
        })
    }, [employee])

    useEffect(() => {
        // if (!seat) return

        const newQueries = { ...queryParams, map: activeLayer, point: seat, employee: null }

        router.replace({
            pathname: location.pathname,
            search: qs.stringify(newQueries, { sort: false, skipNull: true })
        })
    }, [seat, activeLayer])


  

    // if (data && data.status === StatusResponseEnum.NotAuthorized) {
    //     return <Redirect to={`/login?workspace_id=${workspaceId}&project_id=${projectId}`} />
    // }

    return (
        <AppLayout show={isLoading}>
            {/* <p>Welcome project page</p> */}
            <MapContainer />
            <LayerInfoModal />
        </AppLayout>
    )
}

export default ProjectContainer
