import React from 'react'
import { FieldProps, getIn } from 'formik';
import { ErrorMessage, Input, InputFieldProps, InputProps, InputWrapper } from '@/ui/components/Field/Input';


export const ModalSelectInputField = ({ field, form, textarea, ...props }: FieldProps & InputProps & InputFieldProps) => {
    const { customError, ...otherProps } = props
    const errorMessage = getIn(form.touched, field.name) && getIn(form.errors, field.name)

    return (
        <InputWrapper>
            <Input
                {...field}
                {...otherProps}
                as={textarea ? 'textarea' : 'input'}
                value={field.value.name}
                disabled
                $error={errorMessage != null || customError != null}
            />

            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            {customError && <ErrorMessage>{customError}</ErrorMessage>}
        </InputWrapper>
    )
}