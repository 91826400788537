import React, { useState } from 'react'
import Sidebar from '../Sidebar';
import styled from 'styled-components'
import ReportsItems from './ReportsItem';
import ReportModal from './ReportModal';

// const mockData = ["Парковки на завтра", "Брони на вчера", "Количество занятых мест"]
const mockData = ["Нагрузка на рабочее место"]

const Report = () => {

    return (
        <Sidebar>
            <Sidebar.Header title="Отчеты" onAdd={() => alert('Добавление отчета')} />
            <Sidebar.Content>
                {mockData.map(item => <ReportsItems key={'report ' + item} name={item} />)}
            </Sidebar.Content>
        </Sidebar>
    )
}

export default Report