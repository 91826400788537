import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ProjectContainer from './ProjectContainer';
import LoginContainer from './LoginContainer';
import NotFound from '@/components/NotFound';
import requiredAuth from "@/hoc/requiredAuth";

const AppContainer = () => {
    return (
        <Router>
            <Switch>
                {/* <Route exact path="/" component={IndexPage} /> */}
                <Route exact path="/login" component={LoginContainer} />
                <Route exact path="/project/:workspaceId/:projectId" component={requiredAuth(ProjectContainer)} />
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </Router>
    );
}

export default AppContainer