import React from 'react'
import { API } from '@/api';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Sidebar from '../Sidebar';
import TreeView from './TreeView';
import LayerInfoModal from './LayerInfoModal';

const Layers = () => {
    let { workspaceId, projectId } = useParams<{ workspaceId: string, projectId: string }>()
    const { data } = useQuery(
        'tree',
        () => API.projects.tree({ workspaceId: Number(workspaceId), projectId: Number(projectId) }),
        { enabled: !!(workspaceId && projectId), refetchOnMount: false }
    )

    return (
        <Sidebar>
            <Sidebar.Header title="Уровни" onAdd={() => console.log('add layer')} />

            {data?.tree ? (
                <TreeView data={data.tree} />
            ) : (
                <div style={{ padding: '1rem 0' }}>Загрузка...</div>
            )}
        </Sidebar>
    )
}

export default Layers