import useWindowSize from '@/hooks/useWindowSize'
import { useMapStore } from '@/stores/mapStore'
import React from 'react'
import styled from 'styled-components'
import MinusIcon from '../icons/MinusIcon'
import PlusIcon from '../icons/PlusIcon'

const Controls: React.FC = () => {
    const { scale, onRescale } = useMapStore()

    return (
        <ControlsWrapper>
            <ControlButton onClick={() => onRescale({ scale: scale * 1.2 })}>
                <PlusIcon />
            </ControlButton>
            <ControlButton onClick={() => onRescale({ scale: scale / 1.2 })}>
                <MinusIcon />
            </ControlButton>
        </ControlsWrapper>
    )
}

export default Controls

const ControlsWrapper = styled.div`
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
`

const ControlButton = styled.div`
    background: #044D8C;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: all 0.3s;

    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 8px;
    }

    &:hover {
        opacity: 0.7;
    }

    &:active {
        transform: scale(0.8);
    }
`